import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { Button, Card, Form, Header } from 'semantic-ui-react';
import * as Yup from 'yup';
import MyTextInput from '../../common/form/MyTextInput';
import { ModelBase } from '../../models/model-base';
import { useStore } from '../../stores/Store';
import { StoreBase } from '../../stores/StoreBase';

interface Props<T extends ModelBase> {
  store: StoreBase<T>;
  item: T;
  displayProp: string;
  title: string;
  placeholder: string;
}

const GenericDataForm = <T extends ModelBase>({
  store,
  item,
  displayProp,
  title,
  placeholder,
}: Props<T>) => {
  const { modalStore } = useStore();

  const { createItem, updateItem } = store;

  const validationSchema = Yup.object({
    [displayProp]: Yup.string().required(`The ${placeholder} is required`),
  });
  const isCreate = !item.id;

  useEffect(() => {}, []);

  const handleFormSubmit = (submitItem: T) => {
    if (isCreate) {
      createItem(submitItem).then(() => {
        modalStore.closeModal();
      });
    } else {
      updateItem(submitItem).then(() => {
        modalStore.closeModal();
      });
    }
  };

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={item}
      onSubmit={(values) => handleFormSubmit(values)}
    >
      {({ handleSubmit, isValid, isSubmitting, dirty }) => (
        <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
          <Card style={{ width: '100%' }}>
            <Card.Content>
              <Header as='h2' content={title} color='teal' textAlign='center' />
            </Card.Content>
            <Card.Content>
              {!isCreate ? (
                <MyTextInput
                  placeholder='Id'
                  name='id'
                  type='number'
                  readOnly={true}
                />
              ) : null}
              <MyTextInput
                placeholder={placeholder}
                name={displayProp}
                type='text'
              />
            </Card.Content>
            <Card.Content extra>
              <Button
                disabled={isSubmitting || !dirty || !isValid}
                floated='right'
                positive
                type='submit'
                content='Submit'
                loading={isSubmitting}
              />
              <Button
                floated='right'
                type='button'
                content='Cancel'
                onClick={() => modalStore.closeModal()}
              />
            </Card.Content>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default GenericDataForm;
