import React from 'react';
import { Button, Card, Statistic } from 'semantic-ui-react';
import { currencyFormatter } from '../../common/helpers/currencyFormatter';
import { getDisplayDate } from '../../common/helpers/my-date-formatter';

interface Props {
  amount?: number;
  expenseDate: Date;
  expenseType?: string;
  expenseCategory?: string;
  storeName?: string;
  paymentMethod?: string;
  description?: string;
  editClicked: () => void;
  deleteClicked: () => void;
}

const ExpenseDataDisplayCard = ({
  amount,
  expenseDate,
  expenseType,
  expenseCategory,
  storeName,
  paymentMethod,
  description,
  editClicked,
  deleteClicked,
}: Props) => {
  return (
    <Card style={{ width: '100%' }}>
      <Card.Content>
        <Statistic floated='right' size='mini'>
          <Statistic.Value>{currencyFormatter(amount ?? 0)}</Statistic.Value>
        </Statistic>
        <Card.Header>{getDisplayDate(expenseDate)}</Card.Header>
        <Card.Header style={{ paddingTop: '5px', fontSize: 'medium' }}>
          {expenseType}
        </Card.Header>
        <Card.Meta>
          {expenseCategory} - {storeName}
        </Card.Meta>
        <Card.Meta>{paymentMethod}</Card.Meta>
        <Card.Description>{description}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className='ui two buttons'>
          <Button
            basic
            content='Edit'
            color='green'
            icon='edit'
            labelPosition='left'
            onClick={() => editClicked()}
          />
          <Button
            basic
            content='Delete'
            color='red'
            icon='trash alternate outline'
            labelPosition='left'
            onClick={() => deleteClicked()}
          />
        </div>
      </Card.Content>
    </Card>
  );
};

export default ExpenseDataDisplayCard;
