import { useStore } from '../../stores/Store';
import GenericList from '../base/GenericList';
import { EducationExpense } from '../../models/education-expense';

const EducationExpenseList = () => {
  const {
    educationExpenseStore,
    childStore,
    educationExpenseTypeStore,
  } = useStore();

  const getDeleteWarningMessage = (educationExpense: EducationExpense) => {
    return `Are you sure you want to delete the EducationExpense of with Id ${educationExpense.id} ?`;
  };

  const getNewItem = () => {
    const educationExpense: EducationExpense = {
      id: 0,
      amount: 0,
      expenseDate: new Date(),
    };
    return educationExpense;
  };

  return (
    <GenericList
      store={educationExpenseStore}
      title='Education Expenses'
      otherTitle='Education Expense'
      columns={[
        {
          dataType: 'SelectInput',
          displayPropName: 'child.childName',
          store: childStore,
          placeholder: 'Child',
          propName: 'childId',
          required: true,
          requiredMsg: 'Child is required',
        },
        {
          dataType: 'SelectInput',
          displayPropName: 'expenseType.expenseName',
          store: educationExpenseTypeStore,
          placeholder: 'Expense Type',
          propName: 'expenseTypeId',
          required: true,
          requiredMsg: 'Expense Type is required',
        },
        {
          dataType: 'DateInput',
          placeholder: 'Expense Date',
          propName: 'expenseDate',
          required: true,
          requiredMsg: 'Expense Date is required',
          sorted: 'descending',
        },
        {
          dataType: 'CurrencyInput',
          placeholder: 'Amount',
          propName: 'amount',
          required: true,
          requiredMsg: 'Amount is required',
        },
        {
          dataType: 'TextArea',
          placeholder: 'Description',
          propName: 'description',
          required: false,
        },
      ]}
      getDeleteWarningMessage={getDeleteWarningMessage}
      getNewItem={getNewItem}
    />
  );
};

export default EducationExpenseList;
