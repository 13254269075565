import { CarsSpecialLink } from '../models/cars-special-link';
import { StoreBase } from './StoreBase';

export default class CarsSpecialLinkStore extends StoreBase<CarsSpecialLink> {
  constructor() {
    super('CarsSpecialLinks');
  }

  protected sortItems = (items: CarsSpecialLink[]) => {
    return items.sort(({ carsId: a }, { carsId: b }) => {
      if (a && b) {
        return a > b ? 1 : a < b ? -1 : 0;
      } else if(a && !b) {
        return 1;
      } else if(!a && b) {
        return -1;
      }
      return 0;
    });
  };

}
