import { useField } from 'formik';
import React from 'react';
import { Form, Input, Label } from 'semantic-ui-react';

interface Props {
  placeholder: string;
  name: string;
  type?: string;
  label?: string;
  readOnly?: boolean;
  changeHandler?: (data: number) => void;
}

const MyCurrencyInput = ({
  placeholder,
  name,
  type,
  label,
  readOnly,
  changeHandler,
}: Props) => {
  const [field, meta, helpers] = useField(name);
  const propsForField = { placeholder, name, type, label, readOnly };

  return (
    <Form.Field>
      <Input
        error={meta.touched && !!meta.error}
        {...field}
        {...propsForField}
        type='number'
        labelPosition='left'
        onChange={(e) => {
          helpers.setValue(e.target.value);
          if (changeHandler) {
            changeHandler(+e.target.value);
          }
        }}
      >
        <Label basic>$</Label>
        <input />
      </Input>
      {meta.touched && meta.error ? (
        <Label basic color='red'>
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
};

export default MyCurrencyInput;
