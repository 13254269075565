import { Child } from '../models/child';
import { StoreBase } from './StoreBase';

export default class ChildStore extends StoreBase<Child> {
  constructor() {
    super('Children');
  }

  protected sortItems = (items: Child[]) => {
    return items.sort(({ childName: a }, { childName: b }) => {
      return a > b ? 1 : a < b ? -1 : 0;
    });
  };

  protected getDisplayText = (item: Child) => {
    return item.childName;
  };
}
