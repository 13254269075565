import { format } from 'date-fns';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import agent from '../api/agent';
import { getDisplayDate } from '../common/helpers/my-date-formatter';
import { CardTransaction } from '../models/card-transaction';
import { CommonCardPaymentsDto } from '../models/common-card-payments-dto';
import { Expense } from '../models/expense';
import { store } from './Store';
import { StoreBase } from './StoreBase';

export default class CardTransactionStore extends StoreBase<CardTransaction> {
  extraExpenses: Expense[] = [];
  searchText: string = '';
  commonCardPaymentMonths: string[] = [];
  commonCardAmounts?: CommonCardPaymentsDto;

  constructor() {
    super('CardTransactions');

    makeObservable(this, {
      extraExpenses: observable,
      searchText: observable,
      commonCardPaymentMonths: observable,
      commonCardAmounts: observable,
      loadExtraExpenses: action,
      updateExtraExpensesIfNeeded: action,
      updateCardTransactionInStore: action,
      setSearchText: action,
      removeExpensesNotInFilteredFromStore: action,
      addAllAsNewExpenses: action,
      addAllAsNewIncomes: action,
      removeFilteredExpensesFromStore: action,
      removeCardTransactionFromStore: action,
      loadCommonCardPaymentMonths: action,
      loadCommonCardAmounts: action,
      incomeItems: computed,
      expenseItems: computed,
      filteredExpenseItems: computed,
      filteredIncomeItems: computed,
      commonCardPaymentMonthsForSelectList: computed,
      allCommonCardExpenses: computed,
    });
  }

  protected sortItems = (items: CardTransaction[]) => {
    return items.sort(({ transactionDate: ad }, { transactionDate: bd }) => {
      const a = ad.getTime();
      const b = bd.getTime();
      return b > a ? 1 : b < a ? -1 : 0;
    });
  };

  setItem = (item: CardTransaction) => {
    item.transactionDate = new Date(item.transactionDate);
    item.postedDate = new Date(item.postedDate);
    this.itemRegistry.set(item.id, item);
  };

  uploadCardFiles = async (files: File[]) => {
    let allOk = true;
    // this.setLoading(true);
    try {
      for (const file of files) {
        const data = new FormData();
        data.append('file', file);
        if (!(await agent.CardTransactions.uploadCardFile(data))) {
          allOk = false;
        }
      }
    } catch (error: any) {
      allOk = false;
      console.log(error);
      toast.error(error.message);
    } finally {
      // this.setLoading(false);
    }
    return allOk;
  };

  get incomeItems() {
    return this.items.filter((x) => x.isIncome);
  }

  get expenseItems() {
    return this.items.filter((x) => !x.isIncome);
  }

  get filteredExpenseItems() {
    if (this.searchText.length === 0) {
      return this.expenseItems;
    }
    const txt = this.searchText.toLowerCase();
    return this.expenseItems.filter(
      (x) =>
        `${x.amount}`.includes(txt) ||
        x.expenseCategory?.expenseCategoryValue.toLowerCase().includes(txt) ||
        getDisplayDate(x.transactionDate).toLowerCase().includes(txt) ||
        x.expenseType?.typeName.toLowerCase().includes(txt) ||
        x.card?.methodName.toLowerCase().includes(txt) ||
        x.store?.storeName.toLowerCase().includes(txt) ||
        x.description?.toLowerCase().includes(txt)
    );
  }

  get filteredIncomeItems() {
    if (this.searchText.length === 0) {
      return this.incomeItems;
    }
    const txt = this.searchText.toLowerCase();
    return this.incomeItems.filter(
      (x) =>
        `${x.creditAmount}`.includes(txt) ||
        x.incomePerson?.personName.toLowerCase().includes(txt) ||
        getDisplayDate(x.transactionDate).toLowerCase().includes(txt) ||
        x.incomeType?.incomeTypeValue.toLowerCase().includes(txt) ||
        x.card?.methodName.toLowerCase().includes(txt) ||
        x.description?.toLowerCase().includes(txt)
    );
  }

  loadExtraExpenses = async () => {
    this.loading = true;
    try {
      this.extraExpenses.splice(0, this.extraExpenses.length);
      const expenses = await agent.CardTransactions.getExtraExpenses();
      // console.log(expenses);
      runInAction(() => {
        expenses.forEach((expense) => {
          expense.expenseDate = new Date(expense.expenseDate);
          this.extraExpenses.push(expense);
        });
      });
    } catch (error: any) {
      console.log(error);
      toast.error(error.message);
    } finally {
      this.setLoading(false);
    }
  };

  updateExtraExpensesIfNeeded = async (expense: Expense) => {
    const index = this.extraExpenses.findIndex((x) => x.id === expense.id);
    if (index >= 0) {
      if (JSON.stringify(this.extraExpenses[index]) !== JSON.stringify(expense)) {
        this.extraExpenses[index] = expense;
        await this.loadExtraExpenses();
      }
    }
  };

  removeCardTransactionFromStore = (cardTransaction: CardTransaction) => {
    this.itemRegistry.delete(cardTransaction.id);
  };

  updateCardTransactionInStore = (cardTransaction: CardTransaction) => {
    if (cardTransaction.storeId !== cardTransaction.store?.id) {
      cardTransaction.store = store.storeStore.getItem(cardTransaction.storeId ?? 0);
    }
    if (cardTransaction.expenseTypeId !== cardTransaction.expenseType?.id) {
      cardTransaction.expenseType = store.expenseTypeStore.getItem(cardTransaction.expenseTypeId ?? 0);
    }
    if (cardTransaction.expenseCategoryId !== cardTransaction.expenseCategory?.id) {
      cardTransaction.expenseCategory = store.expenseCategoryStore.getItem(cardTransaction.expenseCategoryId ?? 0);
    }
    if (cardTransaction.incomePersonId !== cardTransaction.incomePerson?.id) {
      cardTransaction.incomePerson = store.incomePersonStore.getItem(cardTransaction.incomePersonId ?? 0);
    }
    if (cardTransaction.incomeTypeId !== cardTransaction.incomeType?.id) {
      cardTransaction.incomeType = store.incomeTypeStore.getItem(cardTransaction.incomeTypeId ?? 0);
    }
    cardTransaction.amount = +cardTransaction.amount;
    cardTransaction.creditAmount = +cardTransaction.creditAmount;
    this.itemRegistry.set(cardTransaction.id, cardTransaction);
  };

  setSearchText = (text: string) => {
    this.searchText = text;
  };

  removeExpensesNotInFilteredFromStore = () => {
    const removeList = this.expenseItems.filter((x) => this.filteredExpenseItems.findIndex((y) => y.id === x.id) < 0);
    removeList.forEach((x) => this.itemRegistry.delete(x.id));
  };

  removeFilteredExpensesFromStore = () => {
    this.filteredExpenseItems.forEach((x) => this.itemRegistry.delete(x.id));
  };

  addAllAsNewExpenses = async () => {
    this.loading = true;
    try {
      const ok = await agent.CardTransactions.addNewExpenses(this.expenseItems);
      if (ok) {
        runInAction(() => {
          this.expenseItems.forEach((x) => this.itemRegistry.delete(x.id));
        });
      }
      return ok;
    } catch (error: any) {
      console.log(error);
      toast.error(error.message);
      return false;
    } finally {
      this.setLoading(false);
    }
  };

  addAllAsNewIncomes = async () => {
    this.loading = true;
    try {
      const ok = await agent.CardTransactions.addNewExpenses(this.incomeItems);
      if (ok) {
        runInAction(() => {
          this.incomeItems.forEach((x) => this.itemRegistry.delete(x.id));
        });
      }
      return ok;
    } catch (error: any) {
      console.log(error);
      toast.error(error.message);
      return false;
    } finally {
      this.setLoading(false);
    }
  };

  get commonCardPaymentMonthsForSelectList() {
    const items = this.commonCardPaymentMonths;
    if (items && items.length > 0) {
      const retItems: { text: string; value: string }[] = [];
      items.forEach((x) => {
        const date = new Date(x);
        const key = format(date, 'yyyy-MM');
        const text = format(date, 'MMMM, yyyy');
        retItems.push({ text: text, value: key });
      });
      return retItems.sort(({ value: a }, { value: b }) => {
        return (a > b ? 1 : a < b ? -1 : 0) * -1;
      });
    } else {
      return [];
    }
  }

  loadCommonCardPaymentMonths = async () => {
    this.loading = true;
    this.commonCardPaymentMonths.splice(0, this.commonCardPaymentMonths.length);
    try {
      const items = await agent.CardTransactions.getCommonCardPaymentMonths();
      if (items) {
        runInAction(() => {
          items.forEach((item) => {
            this.commonCardPaymentMonths.push(item);
          });
        });
      }
    } catch (error: any) {
      console.log(error);
      toast.error(error.message);
      return false;
    } finally {
      this.setLoading(false);
    }
  };

  private fixDates = (expenses: Expense[]) => {
    expenses.forEach((x) => (x.expenseDate = new Date(x.expenseDate)));
  };

  loadCommonCardAmounts = async (month?: string) => {
    this.loading = true;
    this.commonCardAmounts = undefined;
    try {
      if (month) {
        const cardAmounts = await agent.CardTransactions.getCommonCardAmounts(month);
        this.fixDates(cardAmounts.asangaExpenses);
        this.fixDates(cardAmounts.darshiExpenses);
        this.fixDates(cardAmounts.commonExpenses);
        this.fixDates(cardAmounts.extraExpenses);

        let id = 1;
        cardAmounts.missingExpenses.forEach((x) => {
          if (x.id === 0) {
            x.id = id;
            id += 1;
          }
          x.transactionDate = new Date(x.transactionDate);
          x.postedDate = new Date(x.postedDate);
        });

        if (cardAmounts) {
          runInAction(() => {
            this.commonCardAmounts = cardAmounts;
          });
        }
      }
    } catch (error: any) {
      console.log(error);
      toast.error(error.message);
      return false;
    } finally {
      this.setLoading(false);
    }
  };

  get allCommonCardExpenses() {
    if (this.commonCardAmounts) {
      const all = [
        ...this.commonCardAmounts.asangaExpenses,
        ...this.commonCardAmounts.darshiExpenses,
        ...this.commonCardAmounts.commonExpenses,
      ];
      return all;
    }
    return [];
  }
}
