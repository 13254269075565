import { IncomeType } from '../models/income-type';
import { StoreBase } from './StoreBase';

export default class IncomeTypeStore extends StoreBase<IncomeType> {
  constructor() {
    super('IncomeTypes');
  }

  protected sortItems = (items: IncomeType[]) => {
    return items.sort(({ incomeTypeValue: a }, { incomeTypeValue: b }) => {
      return a > b ? 1 : a < b ? -1 : 0;
    });
  };

  protected getDisplayText = (item: IncomeType) => {
    return item.incomeTypeValue;
  };
}
