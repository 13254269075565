import { observer } from 'mobx-react-lite';
import { Card, Container, Header } from 'semantic-ui-react';

import PeopleSummaryByMonthDto from '../../models/people-summary-by-month-dto';
import { Person } from '../../stores/IncomeExpenseStore';
import MonthSummary from './MonthSummary';

interface Props {
  title: string;
  data: PeopleSummaryByMonthDto[];
  person: Person;
}

const MonthlySummaries = ({ title, data, person }: Props) => {
  return (
    <Container fluid>
      <Header as='h2'>{title}</Header>
      <Card.Group>
        {data.map((x) => (
          <MonthSummary key={x.id} summary={x} person={person} />
        ))}
      </Card.Group>
    </Container>
  );
};

export default observer(MonthlySummaries);
