import { observer } from 'mobx-react-lite';
import { Tab } from 'semantic-ui-react';

import { useStore } from '../stores/Store';
import ChartAndDataByMonth from './ChartAndDataByMonth';

const ByYearSummary = () => {
  const { incomeExpenseStore } = useStore();
  const { allYears } = incomeExpenseStore;

  const getTabPanes = () => {
    return allYears
      .sort((x, y) => y - x)
      .map((x) => {
        return {
          menuItem: `${x}`,
          render: () => (
            <Tab.Pane>
              <ChartAndDataByMonth year={x} />;
            </Tab.Pane>
          ),
        };
      });
  };

  return <Tab panes={getTabPanes()} />;
};

export default observer(ByYearSummary);
