import React, { useState } from 'react';
import { Button, Grid, Header, Segment } from 'semantic-ui-react';

import FileDropZone from './FileDropZone';

interface Props {
  uploadFiles: (files: File[]) => Promise<boolean>;
}

const FileUploadWidget = ({ uploadFiles }: Props) => {
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Grid stackable>
      <Grid.Column width={8}>
        <Header sub color='teal' content='Drop File(s) Here' />
        <FileDropZone setFiles={setFiles} />
      </Grid.Column>
      <Grid.Column width={8}>
        <Header sub color='teal' content='Upload File List' />
        <Segment attached='top'>
          {files && files.map((file) => <div key={file.lastModified}>{file.name}</div>)}
          {(!files || files.length === 0) && <div>No files to upload</div>}
        </Segment>
        <Button.Group attached='bottom'>
          <Button basic color='red' disabled={!files || files.length === 0} onClick={() => setFiles([])}>
            Cancel
          </Button>
          <Button
            basic
            color='blue'
            loading={loading}
            disabled={!files || files.length === 0}
            onClick={async () => {
              setLoading(true);
              try {
                const ok = await uploadFiles(files);
                if (ok) {
                  setFiles([]);
                }
              } catch (error: any) {
                console.log(error);
              } finally {
                setLoading(false);
              }
            }}
          >
            Upload
          </Button>
        </Button.Group>
      </Grid.Column>
    </Grid>
  );
};

export default FileUploadWidget;
