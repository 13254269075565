import { useStore } from '../../../stores/Store';
import GenericDataList from '../GenericDataList'; 
import { EducationIncomeType } from '../../../models/education-income-type';

const EducationIncomeTypeList = () => {
  const { educationIncomeTypeStore } = useStore();

  return (
    <GenericDataList<EducationIncomeType>
      store={educationIncomeTypeStore}
      displayProp='incomeName'
      title='Education Income Types'
      editTitle='Education Income Type'
      colTitle='Income Name'
      addButtonTitle='Add Education Income Type'
    />
  );
};

export default EducationIncomeTypeList;
