import { CarExpenseType } from '../models/car-expense-type';
import { StoreBase } from './StoreBase';

export default class CarExpenseTypeStore extends StoreBase<CarExpenseType> {
  constructor() {
    super('CarExpenseTypes');
  }

  protected sortItems = (items: CarExpenseType[]) => {
    return items.sort(({ typeName: a }, { typeName: b }) => {
      if (a && b) {
        return a > b ? 1 : a < b ? -1 : 0;
      } else if (a && !b) {
        return 1;
      } else if (!a && b) {
        return -1;
      }
      return 0;
    });
  };

  protected getDisplayText = (item: CarExpenseType) => {
    return item.typeName ?? '';
  };
}
