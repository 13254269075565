import { getConformationFunc } from '../helpers/ConfirmDialog';

export const deleteWarningFunc = (
  message: string,
  okHandler: () => Promise<boolean>
) => {
  getConformationFunc(
    'Delete Warning',
    message,
    okHandler,
    'white',
    '#cc362b',
    'red'
  );
};
