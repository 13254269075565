import { observer } from 'mobx-react-lite';
import React, { SyntheticEvent, useEffect } from 'react';
import { Button, Grid, Input } from 'semantic-ui-react';
import { getConformationFunc } from '../../common/helpers/ConfirmDialog';
import { CardTransaction } from '../../models/card-transaction';
import { FieldProps } from '../../pages/base/FieldProps';
import GenericEditForm from '../../pages/base/GenericEditForm';
import GenericItemList from '../../pages/base/GenericItemList';
import { useStore } from '../../stores/Store';
import CardExpenseCard from './CardExpenseCard';
import { getCardExpenseDisplayFieldProps } from './CardExpenseData';

const NewExpenses = () => {
  const {
    expenseStore,
    cardTransactionStore,
    expenseTypeStore,
    expenseCategoryStore,
    storeStore,
    paymentMethodStore,
    modalStore,
  } = useStore();
  const {
    filteredExpenseItems,
    expenseItems,
    removeCardTransactionFromStore,
    loadItemsIfNeeded,
    reloadItems,
    updateCardTransactionInStore,
    searchText,
    setSearchText,
    removeExpensesNotInFilteredFromStore,
    removeFilteredExpensesFromStore,
    addAllAsNewExpenses,
  } = cardTransactionStore;

  useEffect(() => {
    loadItemsIfNeeded();
  }, [loadItemsIfNeeded]);

  const columns: FieldProps[] = getCardExpenseDisplayFieldProps(
    expenseStore,
    expenseTypeStore,
    expenseCategoryStore,
    storeStore,
    paymentMethodStore
  );

  const editSubmited = (item: CardTransaction) => {
    updateCardTransactionInStore(item);
  };

  const editForm = (item: CardTransaction) => {
    return (
      <GenericEditForm
        store={cardTransactionStore}
        item={item}
        title={'Edit Expense'}
        fields={columns}
        editSubmited={editSubmited}
      />
    );
  };

  const editClicked = (item: CardTransaction) => {
    modalStore.openModal(editForm(item));
  };

  return (
    <>
      <Grid stackable>
        <Grid.Column floated='left' textAlign='left' width={10}>
          <Button
            color='green'
            content={'Save All'}
            onClick={() => {
              getConformationFunc(
                'Conformation',
                'Are you sure you wish to add all the entries as expenses?',
                async () => {
                  return await addAllAsNewExpenses();
                },
                'white',
                '#21BA45',
                'green'
              );
            }}
            disabled={expenseItems.length === 0}
            style={{ marginRight: '10px', marginBottom: '10px' }}
          ></Button>
          <Button
            color='blue'
            content={'Remove All Non Visible'}
            onClick={() => {
              removeExpensesNotInFilteredFromStore();
            }}
            disabled={expenseItems.length === filteredExpenseItems.length}
            style={{ marginRight: '10px', marginBottom: '10px' }}
          ></Button>
          <Button
            color='blue'
            content={'Remove Visible'}
            onClick={() => {
              removeFilteredExpensesFromStore();
            }}
            disabled={expenseItems.length === filteredExpenseItems.length}
          ></Button>
        </Grid.Column>
        <Grid.Column floated='right' width={6} className='search-text'>
          <Input
            icon='search'
            placeholder='Search...'
            value={searchText}
            onChange={(e: SyntheticEvent<HTMLInputElement>) => {
              setSearchText(e.currentTarget.value);
            }}
          />
        </Grid.Column>
      </Grid>
      <Grid className='mobile tablet only'>
        {filteredExpenseItems.map((item) => (
          <Grid.Row key={item.id}>
            <Grid.Column>
              <CardExpenseCard
                cardTransaction={item}
                editClicked={editClicked}
              />
            </Grid.Column>
          </Grid.Row>
        ))}
      </Grid>
      <GenericItemList
        store={cardTransactionStore}
        columns={columns}
        items={filteredExpenseItems}
        otherTitle='Expense'
        className='tablet or lower hidden'
        deleteClicked={(item: CardTransaction) => {
          removeCardTransactionFromStore(item);
        }}
        refreshClicked={() => {
          reloadItems();
        }}
        editSubmited={editSubmited}
      />
    </>
  );
};

export default observer(NewExpenses);
