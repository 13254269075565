import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Button } from 'semantic-ui-react';

import {
    getExpenseDeleteWarningMessage, getExpenseDisplayFieldProps
} from '../../controls/expenses/ExpenseData';
import { Expense } from '../../models/expense';
import { useStore } from '../../stores/Store';
import GenericItemList from '../base/GenericItemList';

const DuplicateExpenses = () => {
  const { expenseStore, expenseTypeStore, expenseCategoryStore, storeStore, paymentMethodStore } = useStore();

  const columns = getExpenseDisplayFieldProps(
    expenseStore,
    expenseTypeStore,
    expenseCategoryStore,
    storeStore,
    paymentMethodStore
  );

  const { loadDuplicateExpenses, duplicateExpenses, confirmAndDeleteDuplicate, confirmAndDeleteAllDuplicate } =
    expenseStore;

  useEffect(() => {
    loadDuplicateExpenses();
  }, [loadDuplicateExpenses]);

  const handleDelete = (expense: Expense) => {
    confirmAndDeleteDuplicate(getExpenseDeleteWarningMessage(expense), expense.id);
  };

  const handleDeleteAllDuplicates = () => {
    confirmAndDeleteAllDuplicate();
  };

  return (
    <>
      {duplicateExpenses && duplicateExpenses.length > 0 && (
        <Button
          content={'Delete All Duplicates'}
          icon='trash'
          negative={true}
          onClick={handleDeleteAllDuplicates}
        ></Button>
      )}
      <GenericItemList
        items={duplicateExpenses ?? []}
        store={expenseStore}
        otherTitle='Duplicate Expenses'
        columns={columns}
        deleteClicked={handleDelete}
        refreshClicked={() => loadDuplicateExpenses()}
        noEdit
      />

      {/* {duplicateExpenses?.length}
      {duplicateExpenses?.map((exp) => (
        <div key={exp.id}>
          {dateToStr(exp.expenseDate)} {exp.amount}
        </div>
      ))} */}
    </>
  );
};

export default observer(DuplicateExpenses);
