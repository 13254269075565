import { Month } from '../models/month';
import { StoreBase } from './StoreBase';

export default class MonthStore extends StoreBase<Month> {
  constructor() {
    super('Months');
  }

  protected sortItems = (items: Month[]) => {
    return items.sort(({ monthName: a }, { monthName: b }) => {
      return a > b ? 1 : a < b ? -1 : 0;
    });
  };

  protected getDisplayText = (item: Month) => {
    return item.monthName;
  };
}
