import { CarExpense } from '../models/car-expense';
import { PagedStoreBase } from './PagedStoreBase';

export default class CarExpenseStore extends PagedStoreBase<CarExpense> {
  constructor() {
    super('CarExpenses');
  }

  protected sortItems = (items: CarExpense[]) => {
    return items.sort(({ expenseDate: ad }, { expenseDate: bd }) => {
      const a = ad.getTime();
      const b = bd.getTime();
      return b > a ? 1 : b < a ? -1 : 0;
    });
  };

  setItem = (item: CarExpense) => {
    item.expenseDate = new Date(item.expenseDate);
    this.itemRegistry.set(item.id, item);
  };
}
