import { ExpenseType } from '../models/expense-type';
import { StoreBase } from './StoreBase';

export default class ExpenseTypeStore extends StoreBase<ExpenseType> {
  constructor() {
    super('ExpenseTypes');
  }

  protected sortItems = (items: ExpenseType[]) => {
    return items.sort(({ typeName: a }, { typeName: b }) => {
      return a > b ? 1 : a < b ? -1 : 0;
    });
  };

  protected getDisplayText = (item: ExpenseType) => {
    return item.typeName;
  };
}
