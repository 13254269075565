import React, { useState } from 'react';
import { Button, Card, Divider, Header, Segment } from 'semantic-ui-react';
import { AsangaIncome } from '../../models/asanga-income';
import { useStore } from '../../stores/Store';
import GenericEditForm from '../base/GenericEditForm';

const AsangaIncomePage = () => {
  const [addedIncome, setAddedIncome] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const { modalStore, asangaIncomeStore } = useStore();

  const handleResult = (success: boolean, error?: string) => {
    setAddedIncome(success);
    setError(error);
  };

  const newIncome: AsangaIncome = {
    id: 0,
    amount: 0,
    incomeDate: new Date(),
  };

  const handleAddIncome = () => {
    modalStore.openModal(
      <GenericEditForm
        item={newIncome}
        store={asangaIncomeStore}
        title='Add Asanga Income'
        result={handleResult}
        fields={[
          {
            dataType: 'DateInput',
            placeholder: 'Income Date',
            title: 'Date',
            propName: 'incomeDate',
            required: true,
            requiredMsg: 'Income date is required',
            sorted: 'descending',
          },
          {
            dataType: 'CurrencyInput',
            placeholder: 'Amount',
            propName: 'amount',
            required: true,
            requiredMsg: 'Amount is required',
          },
        ]}
      />
    );
  };

  return (
    <Card style={{ margin: 'auto' }}>
      <Card.Content>
        <Card.Header>
          <Header as='h2' color='blue' textAlign='center'>
            Asanga's Income
          </Header>
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <Button
          color='blue'
          content='Add Income'
          style={{ width: '100%' }}
          onClick={() => handleAddIncome()}
        />
        {addedIncome || error ? <Divider horizontal>Info</Divider> : null}
        {addedIncome ? <Segment>Income added successfully</Segment> : null}
        {error ? (
          <>
            <Segment>Failed to add income.</Segment>
            <Segment inverted color='red'>
              {error}
            </Segment>
          </>
        ) : null}
      </Card.Content>
    </Card>
  );
};

export default AsangaIncomePage;
