import { makeObservable, observable, runInAction } from 'mobx';

import agent from '../api/agent';
import { deleteWarningFunc } from '../common/form/DeleteWarning';
import { Expense } from '../models/expense';
import { PagedStoreBase } from './PagedStoreBase';
import { store } from './Store';

export default class ExpenseStore extends PagedStoreBase<Expense> {
  duplicateExpenses: Expense[] = [];

  constructor() {
    super('Expenses');

    makeObservable(this, {
      duplicateExpenses: observable,
    });
  }

  protected sortItems = (items: Expense[]) => {
    return items.sort(({ expenseDate: ad, id: aId }, { expenseDate: bd, id: bId }) => {
      const a = ad.getTime();
      const b = bd.getTime();
      if (a === b) {
        return bId > aId ? 1 : bId < aId ? -1 : 0;
      }
      return b > a ? 1 : b < a ? -1 : 0;
    });
  };

  setItem = (item: Expense) => {
    item.expenseDate = new Date(item.expenseDate);
    this.itemRegistry.set(item.id, item);
    store.cardTransactionStore.updateExtraExpensesIfNeeded(item);
  };

  setDefaultsOnNew = async (newItem: Expense) => {
    let changed = false;
    if (newItem) {
      if (newItem.id <= 0 && newItem.expenseTypeId && newItem.expenseTypeId > 0) {
        const defaultsExpence = await agent.Expenses.getExpenseTypeDefaults(newItem.expenseTypeId);
        if (newItem.canSetPaymentMethod && newItem.paymentMethodId !== defaultsExpence.paymentMethodId) {
          newItem.paymentMethodId = defaultsExpence.paymentMethodId;
          newItem.paymentMethodIdAuto = true;
          changed = true;
        }
        if (newItem.canSetExpenseCategory && newItem.expenseCategoryId !== defaultsExpence.expenseCategoryId) {
          newItem.expenseCategoryId = defaultsExpence.expenseCategoryId;
          newItem.expenseCategoryIdAuto = true;
          changed = true;
        }
        if (newItem.canSetStore && newItem.storeId !== defaultsExpence.storeId) {
          newItem.storeId = defaultsExpence.storeId;
          newItem.storeIdAuto = true;
          changed = true;
        }
        if (newItem.canSetAmount && newItem.amount !== defaultsExpence.amount) {
          newItem.amount = defaultsExpence.amount;
          newItem.amountAuto = true;
          changed = true;
        }
        if (newItem.canSetDescription && newItem.description !== defaultsExpence.description) {
          newItem.description = defaultsExpence.description ?? '';
          newItem.descriptionAuto = true;
          changed = true;
        }
      }
    } else {
      console.log('New item is undefined');
    }
    return changed;
  };

  loadDuplicateExpenses = async () => {
    this.setLoading(true);
    try {
      runInAction(() => {
        this.duplicateExpenses.splice(0, this.duplicateExpenses.length);
      });
      const duplicateExpenses = await agent.Expenses.getDuplicates();
      runInAction(() => {
        duplicateExpenses.forEach((x) => {
          x.expenseDate = new Date(x.expenseDate);
          this.duplicateExpenses.push(x);
        });
      });
    } catch (error: any) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  confirmAndDeleteDuplicate = (message: string, id: number) => {
    deleteWarningFunc(message, async () => {
      return await this.deleteDuplicate(id);
    });
  };

  deleteDuplicate = async (id: number) => {
    // console.log('Going to delete');
    const expense = this.duplicateExpenses.find((x) => x.id === id);
    if (expense) {
      // console.log('Found Expense');
      const duplicate = this.duplicateExpenses.find(
        (x) =>
          x.id !== id &&
          x.amount === expense.amount &&
          ((!x.description && !expense.description) ||
            x.description?.toLowerCase() === expense.description?.toLowerCase()) &&
          x.expenseCategoryId === expense.expenseCategoryId &&
          x.expenseDate.getFullYear() === expense.expenseDate.getFullYear() &&
          x.expenseDate.getMonth() === expense.expenseDate.getMonth() &&
          x.expenseDate.getDate() === expense.expenseDate.getDate() &&
          x.expenseDate.getDay() === expense.expenseDate.getDay() &&
          x.expenseTypeId === expense.expenseTypeId &&
          x.paymentMethodId === expense.paymentMethodId &&
          x.storeId === expense.storeId
      );
      if (duplicate) {
        // console.log('Found Duplicate Expense');
        const ok = await this.deleteItem(id);
        if (ok) {
          // console.log('Deleted Expense');
          const expIndex = this.duplicateExpenses.findIndex((x) => x.id === id);
          if (expIndex >= 0) {
            // console.log('Found Expense Index');
            runInAction(() => {
              this.duplicateExpenses.splice(expIndex, 1);
            });
          }
          const duplicateIndex = this.duplicateExpenses.findIndex((x) => x.id === duplicate.id);
          if (duplicateIndex >= 0) {
            // console.log('Found Duplicate Index');
            runInAction(() => {
              this.duplicateExpenses.splice(duplicateIndex, 1);
            });
          }
        }
        return ok;
      }
    }
    return false;
  };

  confirmAndDeleteAllDuplicate = () => {
    const message = 'Are you sure you want to delete all duplicates?';
    deleteWarningFunc(message, async () => {
      let allGood = true;
      while (this.duplicateExpenses.length > 0 && allGood) {
        const ok = await this.deleteDuplicate(this.duplicateExpenses[0].id);
        if (!ok) {
          allGood = false;
        }
      }
      return allGood;
    });
  };
}
