import { observer } from 'mobx-react-lite';
import React from 'react';
import { CardTransaction } from '../../models/card-transaction';
import { useStore } from '../../stores/Store';
import ExpenseDataDisplayCard from '../expenses/ExpenseDataDisplayCard';

interface Props {
  cardTransaction: CardTransaction;
  editClicked: (item: CardTransaction) => void;
}

const CardExpenseCard = ({ cardTransaction, editClicked }: Props) => {
  const { cardTransactionStore } = useStore();
  const { removeCardTransactionFromStore } = cardTransactionStore;

  const handleDelete = () => {
    removeCardTransactionFromStore(cardTransaction);
  };

  return (
    <ExpenseDataDisplayCard
      amount={cardTransaction.amount}
      expenseDate={cardTransaction.transactionDate}
      expenseType={cardTransaction.expenseType?.typeName}
      expenseCategory={cardTransaction.expenseCategory?.expenseCategoryValue}
      paymentMethod={cardTransaction.card.methodName}
      storeName={cardTransaction.store?.storeName}
      description={cardTransaction.description}
      editClicked={() => {
        editClicked(cardTransaction);
      }}
      deleteClicked={handleDelete}
    />
  );
};

export default observer(CardExpenseCard);
