import 'semantic-ui-css/semantic.min.css';
// import 'semantic-ui-forest-themes/semantic.yeti.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

import {
    BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement,
    Title, Tooltip
} from 'chart.js';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import App from './layout/App';
import ScrollToTop from './layout/ScrollToTop';
import reportWebVitals from './reportWebVitals';
import { store, StoreContext } from './stores/Store';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

export const history = createBrowserHistory();

ReactDOM.render(
  // <React.StrictMode>
  <StoreContext.Provider value={store}>
    <Router history={history}>
      <ScrollToTop />
      <App />
    </Router>
  </StoreContext.Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
