import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  Item,
  Segment,
  Select,
  Statistic,
} from 'semantic-ui-react';
import { currencyFormatter } from '../../common/helpers/currencyFormatter';
import LoadingComponent from '../../layout/LoadingComponent';
import { useStore } from '../../stores/Store';

const AutoPaymentsPage = () => {
  const { autoPaymentStore } = useStore();
  const {
    loadMonths,
    monthsForSelectList,
    loadPaymentsForMonth,
    payments,
    canProcess,
    processPayments,
    toggleProcess,
    toggleDelete,
  } = autoPaymentStore;

  const [month, setMonth] = useState(undefined as number | undefined);
  const [key, setKey] = useState(1);

  useEffect(() => {
    loadMonths();
  }, [loadMonths]);

  useEffect(() => {
    if (month) {
      const year = Math.floor(month / 100);
      const monthTemp = month % 100;

      loadPaymentsForMonth(year, monthTemp);
    }
  }, [loadPaymentsForMonth, month]);

  if (autoPaymentStore.loading) {
    return <LoadingComponent />;
  }

  const handleProcess = () => {
    processPayments();
  };

  return (
    <Segment>
      <Item>
        <Item.Content>
          <Item.Header as='h2'>Auto Payments</Item.Header>
        </Item.Content>
        <Divider></Divider>
        <Item.Description>
          <Form>
            <Form.Field>
              <label>Month</label>
              <Select
                clearable
                options={monthsForSelectList}
                value={month}
                onChange={(e, d) => {
                  if (d.value) {
                    setMonth(+d.value);
                  }
                }}
                placeholder='Month'
              ></Select>
            </Form.Field>
          </Form>
        </Item.Description>
        {month && (
          <>
            <Divider></Divider>
            <Item.Description>
              <Card.Group>
                {payments.map((payment) => (
                  <Card key={payment.id}>
                    <Card.Content>
                      <Statistic floated='right' size='mini'>
                        <Statistic.Value>
                          {currencyFormatter(payment.amount ?? 0)}
                        </Statistic.Value>
                      </Statistic>
                      <Card.Header>{payment.expenseType?.typeName}</Card.Header>
                      <Card.Meta>Payment Day: {payment.paymentDay}</Card.Meta>
                      <Card.Description>{payment.description}</Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      <Checkbox
                        label='Process'
                        style={{ marginBottom: '10px' }}
                        checked={payment.process}
                        onChange={(e, d) => {
                          // console.log(d.checked);
                          toggleProcess(payment);
                          setKey(key + 1);
                        }}
                        disabled={payment.paidId > 0}
                      />
                      <br />
                      <Checkbox
                        label='Delete'
                        disabled={payment.paidId <= 0}
                        checked={payment.delete}
                        onChange={(e, d) => {
                          // console.log(d.checked);
                          toggleDelete(payment);
                          setKey(key + 1);
                        }}
                      />
                    </Card.Content>
                  </Card>
                ))}
              </Card.Group>
            </Item.Description>
            <Divider></Divider>
            <Item.Description>
              <Button
                content='Process'
                color='blue'
                onClick={handleProcess}
                disabled={!canProcess}
              />
            </Item.Description>
          </>
        )}
      </Item>
    </Segment>
  );
};

export default observer(AutoPaymentsPage);
