import { IncomePerson } from '../models/income-person';
import { StoreBase } from './StoreBase';

export default class IncomePersonStore extends StoreBase<IncomePerson> {
  constructor() {
    super('IncomePersons');
  }

  protected sortItems = (items: IncomePerson[]) => {
    return items.sort(({ personName: a }, { personName: b }) => {
      return a > b ? 1 : a < b ? -1 : 0;
    });
  };

  protected getDisplayText = (item: IncomePerson) => {
    return item.personName;
  };
}
