import { currencyFormatter } from '../../common/helpers/currencyFormatter';
import { getDisplayDate } from '../../common/helpers/my-date-formatter';

export const getDisplayTextHelper = (
  item: any,
  propName: string,
  type: string
): string => {
  const keys = propName.split('.');
  if (keys.length === 0) {
    return '';
  }

  const data = item[keys[0]];
  if (!data) {
    return '';
  }
  if (keys.length === 1) {
    if (type === 'DateInput') {
      return getDisplayDate(data);
    } else if (type === 'CurrencyInput') {
      return currencyFormatter(+data);
    }
    return data.toString();
  } else {
    keys.splice(0, 1);
    return getDisplayTextHelper(data, keys.join('.'), type);
  }
};
