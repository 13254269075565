import React from 'react';
import { Expense } from '../../models/expense';
import GenericEditForm from '../../pages/base/GenericEditForm';
import { useStore } from '../../stores/Store';
import { getExpenseDisplayFieldProps } from './ExpenseData';

interface Props {
  expense: Expense;
}

const EditExpense = ({ expense }: Props) => {
  const {
    expenseStore,
    expenseTypeStore,
    expenseCategoryStore,
    storeStore,
    paymentMethodStore,
  } = useStore();

  return (
    <GenericEditForm
      store={expenseStore}
      item={expense}
      title={expense.id > 0 ? `Edit Expense` : `Add Expense`}
      fields={getExpenseDisplayFieldProps(
        expenseStore,
        expenseTypeStore,
        expenseCategoryStore,
        storeStore,
        paymentMethodStore
      )}
    />
  );
};

export default EditExpense;
