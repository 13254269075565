import { observer } from 'mobx-react-lite';
import React from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';
import { ModelBase } from '../../models/model-base';
import { useStore } from '../../stores/Store';
import { StoreBase } from '../../stores/StoreBase';
import { FieldProps } from './FieldProps';
import GenericEditForm from './GenericEditForm';
import { getDisplayTextHelper } from './GenericHelper';

interface Props<T extends ModelBase> {
  items: T[];
  store: StoreBase<T>;
  otherTitle: string;
  columns: FieldProps[];
  className?: string;
  getDeleteWarningMessage?: (item: T) => string;
  deleteClicked?: (item: T) => void;
  editSubmited?: (item: T) => void;
  noEdit?: boolean;
  noDelete?: boolean;
}

const GenericListTableBody = <T extends ModelBase>({
  items,
  columns,
  store,
  otherTitle,
  getDeleteWarningMessage,
  deleteClicked,
  editSubmited,
  noEdit,
  noDelete,
}: Props<T>) => {
  const { modalStore } = useStore();

  const { confirmAndDelete } = store;

  const handleDelete = (deleteItem: T) => {
    if (deleteClicked) {
      deleteClicked(deleteItem);
    } else if (getDeleteWarningMessage) {
      confirmAndDelete(deleteItem.id, getDeleteWarningMessage(deleteItem));
    }
  };

  const editForm = (item: T) => {
    const itemToUse = item;
    return (
      <GenericEditForm
        store={store}
        item={itemToUse}
        title={itemToUse.id > 0 ? `Edit ${otherTitle}` : `Add ${otherTitle}`}
        fields={columns}
        editSubmited={editSubmited}
      />
    );
  };

  const noButtonColumn = noEdit && noDelete;

  return (
    <Table.Body>
      {items.map((item: T) => (
        <Table.Row key={item.id}>
          {columns.map((col) => (
            <Table.Cell
              key={col.propName}
              textAlign={
                col.dataType === 'CurrencyInput' ||
                col.dataType === 'NumberInput'
                  ? 'right'
                  : 'left'
              }
            >
              {getDisplayTextHelper(
                item,
                col.displayPropName ?? col.propName,
                col.dataType
              )}
            </Table.Cell>
          ))}
          {!noButtonColumn && (
            <Table.Cell>
              <Button.Group size='mini'>
                {!noEdit && (
                  <Button
                    basic
                    size='mini'
                    color='blue'
                    style={{ padding: 10 }}
                    onClick={() => modalStore.openModal(editForm(item))}
                  >
                    <Icon
                      name='edit'
                      color='blue'
                      style={{ padding: 0, margin: 0 }}
                    />
                  </Button>
                )}
                {!noDelete && (
                  <Button
                    basic
                    size='mini'
                    color='red'
                    style={{ padding: 10 }}
                    onClick={() => {
                      handleDelete(item);
                    }}
                  >
                    <Icon
                      name='trash alternate outline'
                      // name='delete'
                      color='red'
                      style={{ padding: 0, margin: 0 }}
                    />
                  </Button>
                )}
              </Button.Group>
            </Table.Cell>
          )}
        </Table.Row>
      ))}
    </Table.Body>
  );
};

export default observer(GenericListTableBody);
