import { observer } from 'mobx-react-lite';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Grid,
  Header,
  Icon,
  Input,
  Table,
} from 'semantic-ui-react';
import LoadingComponent from '../../layout/LoadingComponent';
import { ModelBase } from '../../models/model-base';
import { useStore } from '../../stores/Store';
import { StoreBase } from '../../stores/StoreBase';
import GenericDataForm from './GenericDataForm';

interface Props<T extends ModelBase> {
  store: StoreBase<T>;
  displayProp: string;
  title: string;
  colTitle: string;
  addButtonTitle: string;
  editTitle: string;
}

const GenericDataList = <T extends ModelBase>(props: Props<T>) => {
  const { modalStore } = useStore();
  const {
    store,
    displayProp,
    title,
    colTitle,
    addButtonTitle,
    editTitle,
  } = props;
  const {
    loadItemsIfNeeded,
    reloadItems,
    items,
    loading,
    confirmAndDelete,
  } = store;

  const [searchText, setSearchText] = useState('');
  const [filteredItems, setFilteredItems] = useState(items); // useState(null as T[] | null);

  const handleSearch = () => {
    if (searchText.length > 0) {
      const textUpper = searchText.toUpperCase();
      const tempItems = items.filter((x) =>
        ((x as any)[displayProp] as string).toUpperCase().includes(textUpper)
      );
      setFilteredItems(tempItems);
    } else {
      setFilteredItems(items);
    }
  };

  const newItem: ModelBase = { id: 0, [displayProp]: '' };

  useEffect(() => {
    loadItemsIfNeeded().then(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, items]);

  const handleDelete = (item: T) => {
    confirmAndDelete(
      item.id,
      `Are you sure you want to delete the '${editTitle}' with id ${item.id} ?`
    );
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <Card fluid>
      <Card.Content>
        <Header as='h2' content={title} color='teal' textAlign='left' />
      </Card.Content>
      <Card.Content>
        <Grid stackable>
          <Grid.Column floated='left' textAlign='left' width={8}>
            <Button
              color='blue'
              content={addButtonTitle}
              onClick={() =>
                modalStore.openModal(
                  <GenericDataForm<T>
                    store={store}
                    item={newItem as T}
                    displayProp={displayProp}
                    title={`Create ${editTitle}`}
                    placeholder={colTitle}
                  />
                )
              }
            ></Button>
          </Grid.Column>
          <Grid.Column floated='right' width={8} className='search-text'>
            <Input
              icon='search'
              placeholder='Search...'
              value={searchText}
              onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                setSearchText(e.currentTarget.value);
              }}
            />
          </Grid.Column>
        </Grid>
        <Table sortable celled striped compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell sorted={'ascending'}>
                {colTitle}
              </Table.HeaderCell>
              <Table.HeaderCell collapsing textAlign='center'>
                <Button
                  icon
                  onClick={() => {
                    reloadItems();
                  }}
                >
                  <Icon name='refresh' />
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {filteredItems.map((item) => (
              <Table.Row key={item.id}>
                <Table.Cell>{item.id}</Table.Cell>
                <Table.Cell>{(item as any)[displayProp]}</Table.Cell>
                <Table.Cell>
                  <Button.Group size='mini'>
                    <Button
                      basic
                      size='mini'
                      color='blue'
                      style={{ padding: 10 }}
                      onClick={() =>
                        modalStore.openModal(
                          <GenericDataForm<T>
                            store={store}
                            item={item}
                            displayProp={displayProp}
                            title={`Edit ${editTitle}`}
                            placeholder={colTitle}
                          />
                        )
                      }
                    >
                      <Icon
                        name='edit'
                        color='blue'
                        style={{ padding: 0, margin: 0 }}
                      />
                    </Button>
                    <Button
                      basic
                      size='mini'
                      color='red'
                      style={{ padding: 10 }}
                      onClick={() => handleDelete(item)}
                    >
                      <Icon
                        name='trash alternate outline'
                        // name='delete'
                        color='red'
                        style={{ padding: 0, margin: 0 }}
                      />
                    </Button>
                  </Button.Group>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card.Content>
      <Card.Content extra></Card.Content>
    </Card>
  );
};

export default observer(GenericDataList);
