import { useStore } from '../../../stores/Store';
import GenericDataList from '../GenericDataList'; 
import { SpecialIncomeType } from '../../../models/special-income-type';

const SpecialIncomeTypeList = () => {
  const { specialIncomeTypeStore } = useStore();

  return (
    <GenericDataList<SpecialIncomeType>
      store={specialIncomeTypeStore}
      displayProp='incomeType'
      title='Special Income Types'
      editTitle='Special Income Type'
      colTitle='Income Type'
      addButtonTitle='Add Special Income Type'
    />
  );
};

export default SpecialIncomeTypeList;
