import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Card, Header, Segment, Select } from 'semantic-ui-react';
import { getCardExpenseDisplayFieldProps } from '../../controls/card-transactions/CardExpenseData';
import CommonCardPayment from '../../controls/common-card-payment/CommonCardPayment';
import { getExpenseDisplayFieldProps } from '../../controls/expenses/ExpenseData';
import LoadingComponent from '../../layout/LoadingComponent';
import { useStore } from '../../stores/Store';
import GenericItemList from '../base/GenericItemList';

const CostcoCardPayments = () => {
  const {
    cardTransactionStore,
    expenseStore,
    expenseTypeStore,
    expenseCategoryStore,
    storeStore,
    paymentMethodStore,
  } = useStore();
  const [selectedMonth, setSelectedMonth] = useState(
    undefined as string | undefined
  );

  const {
    loadCommonCardPaymentMonths,
    commonCardPaymentMonthsForSelectList,
    loadCommonCardAmounts,
    commonCardAmounts,
    allCommonCardExpenses,
    loading,
  } = cardTransactionStore;

  useEffect(() => {
    loadCommonCardPaymentMonths();
    return () => {
      loadCommonCardAmounts(undefined);
    };
  }, [loadCommonCardPaymentMonths, loadCommonCardAmounts]);

  return (
    <>
      <Segment.Group>
        <Segment inverted color='teal'>
          <Card.Content as='h3' header='Costco Card Amounts' />
        </Segment>
        <Segment>
          <Select
            clearable
            options={commonCardPaymentMonthsForSelectList}
            value={selectedMonth}
            onChange={(e, d) => {
              setSelectedMonth(d.value as string);
              loadCommonCardAmounts(d.value as string);
            }}
            placeholder={'Month'}
          ></Select>
        </Segment>
        {loading && (
          <div style={{ minHeight: '60vh' }}>
            <LoadingComponent />
          </div>
        )}
        {commonCardAmounts && (
          <>
            <Segment>
              <Header as='h3' content='Amounts To Pay' />
              <CommonCardPayment
                name='Asanga'
                amount={commonCardAmounts.asangaAmt}
                expenses={commonCardAmounts.asangaExpenses}
              />
              <br />
              <br />
              <CommonCardPayment
                name='Darshi'
                amount={commonCardAmounts.darshiAmt}
                expenses={commonCardAmounts.darshiExpenses}
              />
              <br />
              <br />
              <CommonCardPayment
                name='Common'
                amount={commonCardAmounts.commonAmt}
                expenses={commonCardAmounts.commonExpenses}
              />
              <br />
              <br />
              <CommonCardPayment
                name='Total'
                amount={commonCardAmounts.totalAmt}
                expenses={allCommonCardExpenses}
              />
            </Segment>
            <Segment>
              <Header as='h3' content='Extra Expenses' />
              {commonCardAmounts.haveExtraExpenses && (
                <GenericItemList
                  store={expenseStore}
                  columns={getExpenseDisplayFieldProps(
                    expenseStore,
                    expenseTypeStore,
                    expenseCategoryStore,
                    storeStore,
                    paymentMethodStore
                  )}
                  items={commonCardAmounts.extraExpenses}
                  otherTitle='Expense'
                  className='tablet or lower hidden'
                  noEdit={true}
                  noDelete={true}
                />
              )}
              {!commonCardAmounts.haveExtraExpenses && (
                <div>No Extra Expenses</div>
              )}
            </Segment>
            <Segment>
              <Header as='h3' content='Missing Expenses' />
              {commonCardAmounts.haveMissingExpenses && (
                <GenericItemList
                  store={cardTransactionStore}
                  columns={getCardExpenseDisplayFieldProps(
                    expenseStore,
                    expenseTypeStore,
                    expenseCategoryStore,
                    storeStore,
                    paymentMethodStore
                  )}
                  items={commonCardAmounts.missingExpenses}
                  otherTitle='Expense'
                  className='tablet or lower hidden'
                  noEdit={true}
                  noDelete={true}
                />
              )}
              {!commonCardAmounts.haveMissingExpenses && (
                <div>No Missing Expenses</div>
              )}
            </Segment>
          </>
        )}
      </Segment.Group>
    </>
  );
};

export default observer(CostcoCardPayments);
