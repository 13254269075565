import { CarStore } from '../models/car-store';
import { StoreBase } from './StoreBase';

export default class CarStoreStore extends StoreBase<CarStore> {
  constructor() {
    super('CarStores');
  }

  protected sortItems = (items: CarStore[]) => {
    return items.sort(({ storeName: a }, { storeName: b }) => {
      if (a && b) {
        return a > b ? 1 : a < b ? -1 : 0;
      } else if (a && !b) {
        return 1;
      } else if (!a && b) {
        return -1;
      }
      return 0;
    });
  };

  protected getDisplayText = (item: CarStore) => {
    return item.storeName ?? '';
  };
}
