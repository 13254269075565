import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

// import { Header, Icon, Segment } from 'semantic-ui-react';
import FullSummary from '../../common/FullSummary';
import LoadingComponent from '../../layout/LoadingComponent';
import { useStore } from '../../stores/Store';

const AccountsPage = () => {
  const { incomeExpenseStore, userStore } = useStore();
  const { loading, loadMonthly } = incomeExpenseStore;

  useEffect(() => {
    loadMonthly();
  }, [loadMonthly]);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      {/* <Segment placeholder>
        <Header icon>
          <Icon name='home' />
          Some stuff will come here someday 🤞😎
        </Header>
      </Segment> */}
      <div style={{ display: 'flex', marginBottom: !userStore.isDarshi ? '10px' : '0' }}>
        <h1>Savings</h1>
        {userStore.isDarshi && (
          <>
            <img
              src='/assets/kiss-emoji.svg'
              alt='kiss emoji'
              width='32px'
              style={{ marginLeft: '10px', marginTop: '-12px' }}
            />
            <img src='/assets/kiss-emoji.svg' alt='kiss emoji' width='32px' style={{ marginTop: '-12px' }} />
          </>
        )}
      </div>
      <FullSummary />
    </>
  );
};

export default observer(AccountsPage);
