import { getDisplayDate } from '../../common/helpers/my-date-formatter';
import { SpecialIncome } from '../../models/special-income';
import { useStore } from '../../stores/Store';
import GenericList from '../base/GenericList';

const SpecialIncomeList = () => {
  const {
    specialIncomeStore,
    incomePersonStore,
    specialIncomeTypeStore,
  } = useStore();

  const getDeleteWarningMessage = (specialIncome: SpecialIncome) => {
    return `Are you sure you want to delete the SpecialIncome of ${
      specialIncome.incomeType?.incomeType
    } made on ${getDisplayDate(
      new Date(specialIncome.incomeDate)
    )} to the amount of ${specialIncome.amount} ?`;
  };

  const getNewItem = () => {
    const specialIncome: SpecialIncome = {
      id: 0,
      amount: 0,
      incomeDate: new Date(),
    };
    return specialIncome;
  };

  return (
    <GenericList
      store={specialIncomeStore}
      title='Special Incomes'
      otherTitle='Special Income'
      columns={[
        {
          dataType: 'DateInput',
          placeholder: 'Income Date',
          propName: 'incomeDate',
          required: true,
          requiredMsg: 'Income Date is required',
          sorted: 'descending',
        },
        {
          dataType: 'SelectInput',
          displayPropName: 'incomeType.incomeType',
          store: specialIncomeTypeStore,
          placeholder: 'Income Type',
          propName: 'incomeTypeId',
          required: true,
          requiredMsg: 'Income Type is required',
        },
        {
          dataType: 'SelectInput',
          displayPropName: 'incomePerson.personName',
          store: incomePersonStore,
          placeholder: 'Income Person',
          propName: 'incomePersonId',
          required: true,
          requiredMsg: 'Income Person is required',
        },
        {
          dataType: 'CurrencyInput',
          placeholder: 'Amount',
          propName: 'amount',
          required: true,
          requiredMsg: 'Amount is required',
        },
        {
          dataType: 'TextArea',
          placeholder: 'Description',
          propName: 'description',
          required: false,
        },
      ]}
      getDeleteWarningMessage={getDeleteWarningMessage}
      getNewItem={getNewItem}
    />
  );
};

export default SpecialIncomeList;
