import { useStore } from '../../stores/Store';
import GenericList from '../base/GenericList';
import { EducationIncome } from '../../models/education-income';

const EducationIncomeList = () => {
  const {
    educationIncomeStore,
    childStore,
    educationIncomeTypeStore,
  } = useStore();

  const getDeleteWarningMessage = (educationIncome: EducationIncome) => {
    return `Are you sure you want to delete the EducationIncome of with Id ${educationIncome.id} ?`;
  };

  const getNewItem = () => {
    const educationIncome: EducationIncome = {
      id: 0,
      amount: 0,
      incomeDate: new Date(),
    };
    return educationIncome;
  };

  return (
    <GenericList
      store={educationIncomeStore}
      title='Education Incomes'
      otherTitle='Education Income'
      columns={[
        {
          dataType: 'SelectInput',
          displayPropName: 'child.childName',
          store: childStore,
          placeholder: 'Child',
          propName: 'childId',
          required: true,
          requiredMsg: 'Child is required',
        },
        {
          dataType: 'SelectInput',
          displayPropName: 'incomeType.incomeName',
          store: educationIncomeTypeStore,
          placeholder: 'Income Type',
          propName: 'incomeTypeId',
          required: true,
          requiredMsg: 'Income Type is required',
        },
        {
          dataType: 'DateInput',
          placeholder: 'Income Date',
          propName: 'incomeDate',
          required: true,
          requiredMsg: 'Income Date is required',
          sorted: 'descending',
        },
        {
          dataType: 'CurrencyInput',
          placeholder: 'Amount',
          propName: 'amount',
          required: true,
          requiredMsg: 'Amount is required',
        },
        {
          dataType: 'TextArea',
          placeholder: 'Description',
          propName: 'description',
          required: false,
        },
      ]}
      getDeleteWarningMessage={getDeleteWarningMessage}
      getNewItem={getNewItem}
    />
  );
};

export default EducationIncomeList;
