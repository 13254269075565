import { useStore } from '../../../stores/Store';
import GenericDataList from '../GenericDataList'; 
import { ExpenseCategory } from '../../../models/expense-category';

const ExpenseCategoryList = () => {
  const { expenseCategoryStore } = useStore();

  return (
    <GenericDataList<ExpenseCategory>
      store={expenseCategoryStore}
      displayProp='expenseCategoryValue'
      title='Expense Categories'
      editTitle='Expense Category'
      colTitle='Expense Category Value'
      addButtonTitle='Add Expense Category'
    />
  );
};

export default ExpenseCategoryList;
