import { Gas } from '../models/gas';
import { PagedStoreBase } from './PagedStoreBase';

export default class GasStore extends PagedStoreBase<Gas> {
  constructor() {
    super('Gas');
  }

  protected sortItems = (items: Gas[]) => {
    return items.sort(({ gasDate: ad }, { gasDate: bd }) => {
      const a = ad.getTime();
      const b = bd.getTime();
      return b > a ? 1 : b < a ? -1 : 0;
    });
  };

  setItem = (item: Gas) => {
    item.gasDate = new Date(item.gasDate);
    this.itemRegistry.set(item.id, item);
  };
}
