import { Store } from '../models/store';
import { StoreBase } from './StoreBase';

export default class StoreStore extends StoreBase<Store> {
  constructor() {
    super('Stores');
  }

  protected sortItems = (items: Store[]) => {
    return items.sort(({ storeName: a }, { storeName: b }) => {
      return a > b ? 1 : a < b ? -1 : 0;
    });
  };

  protected getDisplayText = (item: Store) => {
    return item.storeName;
  };
}
