import { SpecialIncomeType } from '../models/special-income-type';
import { StoreBase } from './StoreBase';

export default class SpecialIncomeTypeStore extends StoreBase<SpecialIncomeType> {
  constructor() {
    super('SpecialIncomeTypes');
  }

  protected sortItems = (items: SpecialIncomeType[]) => {
    return items.sort(({ incomeType: a }, { incomeType: b }) => {
      return a > b ? 1 : a < b ? -1 : 0;
    });
  };

  protected getDisplayText = (item: SpecialIncomeType) => {
    return item.incomeType;
  };
}
