import { Button, Icon, Table } from 'semantic-ui-react';
import { FieldProps } from './FieldProps';

interface Props {
  columns: FieldProps[];
  refreshClicked?: () => void;
  noButtonColumn?: boolean;
}

const GenericListTableHeader = ({
  columns,
  refreshClicked,
  noButtonColumn,
}: Props) => {
  return (
    <Table.Header>
      <Table.Row>
        {columns.map((col) => (
          <Table.HeaderCell
            key={col.propName}
            sorted={col.sorted}
            textAlign={
              col.dataType === 'CurrencyInput' || col.dataType === 'NumberInput'
                ? 'right'
                : 'left'
            }
          >
            {col.title ?? col.placeholder}
          </Table.HeaderCell>
        ))}
        {!noButtonColumn && (
          <Table.HeaderCell collapsing textAlign='center'>
            <Button
              icon
              onClick={() => {
                if (refreshClicked) {
                  refreshClicked();
                }
              }}
            >
              <Icon name='refresh' />
            </Button>
          </Table.HeaderCell>
        )}
      </Table.Row>
    </Table.Header>
  );
};

export default GenericListTableHeader;
