import React from 'react';
import {
  getIncomeDeleteWarningMessage,
  getIncomeDisplayFieldProps,
} from '../../controls/incomes/IncomeData';
import { Income } from '../../models/income';
import { useStore } from '../../stores/Store';
import GenericList from '../base/GenericList';

const IncomeList = () => {
  const { incomeStore, incomeTypeStore, incomePersonStore } = useStore();

  const getNewItem = () => {
    const income: Income = { id: 0, incomeDate: new Date() };
    return income;
  };

  return (
    <GenericList
      store={incomeStore}
      title='Incomes'
      otherTitle='Income'
      columns={getIncomeDisplayFieldProps(incomeTypeStore, incomePersonStore)}
      getDeleteWarningMessage={getIncomeDeleteWarningMessage}
      getNewItem={getNewItem}
    />
  );
};

export default IncomeList;
