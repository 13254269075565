import React, { SyntheticEvent } from 'react';
import { Button, Grid, Input } from 'semantic-ui-react';
import { getConformationFunc } from '../../common/helpers/ConfirmDialog';
import { CardTransaction } from '../../models/card-transaction';
import { FieldProps } from '../../pages/base/FieldProps';
import GenericItemList from '../../pages/base/GenericItemList';
import { useStore } from '../../stores/Store';

const NewIncomes = () => {
  const {
    cardTransactionStore,
    incomeTypeStore,
    incomePersonStore,
  } = useStore();

  const {
    incomeItems,
    filteredIncomeItems,
    searchText,
    setSearchText,
    removeCardTransactionFromStore,
    reloadItems,
    updateCardTransactionInStore,
    addAllAsNewIncomes,
  } = cardTransactionStore;

  const columns: FieldProps[] = [
    {
      dataType: 'DateInput',
      placeholder: 'Income Date',
      title: 'Date',
      propName: 'transactionDate',
      required: true,
      requiredMsg: 'Income date is required',
      sorted: 'descending',
    },
    {
      dataType: 'SelectInput',
      placeholder: 'Income Type',
      title: 'Type',
      propName: 'incomeTypeId',
      displayPropName: 'incomeType.incomeTypeValue',
      required: true,
      requiredMsg: 'Income type is required',
      store: incomeTypeStore,
    },
    {
      dataType: 'SelectInput',
      placeholder: 'Income Person',
      title: 'Person',
      propName: 'incomePersonId',
      displayPropName: 'incomePerson.personName',
      required: true,
      requiredMsg: 'Income person is required',
      store: incomePersonStore,
    },
    {
      dataType: 'CurrencyInput',
      placeholder: 'Amount',
      propName: 'creditAmount',
      required: true,
      requiredMsg: 'Amount is required',
    },
    {
      dataType: 'TextArea',
      placeholder: 'Description',
      propName: 'description',
      required: false,
    },
  ];

  const editSubmited = (item: CardTransaction) => {
    updateCardTransactionInStore(item);
  };

  return (
    <>
      <Grid stackable>
        <Grid.Column floated='left' textAlign='left' width={10}>
          <Button
            color='green'
            content={'Save All'}
            onClick={() => {
              getConformationFunc(
                'Conformation',
                'Are you sure you wish to add all the entries as incomes?',
                async () => {
                  return await addAllAsNewIncomes();
                },
                'white',
                '#21BA45',
                'green'
              );
            }}
            disabled={incomeItems.length === 0}
            style={{ marginRight: '10px', marginBottom: '10px' }}
          ></Button>
        </Grid.Column>
        <Grid.Column floated='right' width={6} className='search-text'>
          <Input
            icon='search'
            placeholder='Search...'
            value={searchText}
            onChange={(e: SyntheticEvent<HTMLInputElement>) => {
              setSearchText(e.currentTarget.value);
            }}
          />
        </Grid.Column>
      </Grid>
      <GenericItemList
        store={cardTransactionStore}
        columns={columns}
        items={filteredIncomeItems}
        otherTitle='Income'
        className=''
        deleteClicked={(item: CardTransaction) => {
          removeCardTransactionFromStore(item);
        }}
        refreshClicked={() => {
          reloadItems();
        }}
        editSubmited={editSubmited}
      />
    </>
  );
};

export default NewIncomes;
