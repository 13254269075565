import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import debounce from '../common/helpers/debounce';
import { ModelBase } from '../models/model-base';
import { Pagination, PagingParams } from '../models/Pagination';
import { StoreBase } from './StoreBase';

export enum CommonPredicate {
  TEXT = 'text',
}

export class PagedStoreBase<T extends ModelBase> extends StoreBase<T> {
  neverLoaded = true;
  pageToLoad?: number;
  pagination?: Pagination;
  pagingParams = new PagingParams();
  predicate = new Map();
  searchText = '';

  constructor(url: string, typeName = 'Item') {
    super(url, typeName);
    makeObservable(this, {
      neverLoaded: observable,
      pageToLoad: observable,
      pagination: observable,
      pagingParams: observable,
      predicate: observable,
      searchText: observable,
      pageToShow: computed,
      axiosParams: computed,
      setPageToShow: action,
      setPagingParams: action,
      resetPredicate: action,
      addPredicate: action,
      setSearchText: action,
      setPagination: action,
      loadItems: action,
    });
  }

  get pageToShow() {
    return this.pageToLoad ?? this.pagination?.currentPage ?? 1;
  }

  setPageToShow = (pageNumber: number) => {
    if (pageNumber <= 1) {
      pageNumber = 1;
    } else if (this.pagination && this.pagination.totalPages < pageNumber) {
      pageNumber = this.pagination.totalPages;
    }
    this.pageToLoad = pageNumber;
    this.pagingParams.pageNumber = this.pageToLoad;
    this.debounceLoadItems();
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = pagingParams;
  };

  private clearPredicate = () => {
    this.predicate.forEach((value, key) => {
      this.predicate.delete(key);
    });
  };

  resetPredicate = (predicate: string, value: string | Date) => {
    this.clearPredicate();
    this.addPredicate(predicate, value);
    this.pagingParams = new PagingParams();
    runInAction(() => (this.pageToLoad = undefined));
    // this.pageToLoad = undefined;
    this.loadItems();
  };

  addPredicate = (predicate: string, value: string | Date) => {
    this.predicate.set(predicate, value);
  };

  setSearchText = (text: string) => {
    this.searchText = text;
    this.debounceResetPredicates(CommonPredicate.TEXT, this.searchText);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append('pageNumber', this.pagingParams.pageNumber.toString());
    params.append('pageSize', this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === 'startDate') {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  loadItems = async () => {
    this.setDidLoad();
    this.setLoading(true);
    try {
      runInAction(() => {
        this.itemRegistry.clear();
      });
      const result = await this.agent.listPaged(this.axiosParams);
      runInAction(() => {
        result.data.forEach((item) => {
          this.setItem(item);
        });
        this.setPagination(result.pagination);
        this.pageToLoad = undefined;
      });
    } catch (error: any) {
      console.log(error);
      runInAction(() => {
        this.setNoPages();
      });
    } finally {
      this.setLoading(false);
    }
  };

  private setDidLoad() {
    this.neverLoaded = false;
  }

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  private setNoPages() {
    this.pagination = {
      currentPage: 0,
      itemsPerPage: 20,
      totalItems: 0,
      totalPages: 0,
    };
  }

  debounceLoadItems = debounce(this.loadItems, 400);
  debounceResetPredicates = debounce(this.resetPredicate, 1000);
}
