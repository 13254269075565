import React, { useState } from 'react';
import { Button, Icon, Input, Label, Segment } from 'semantic-ui-react';
import { currencyFormatter } from '../../common/helpers/currencyFormatter';
import { Expense } from '../../models/expense';
import GenericItemList from '../../pages/base/GenericItemList';
import { useStore } from '../../stores/Store';
import { getExpenseDisplayFieldProps } from '../expenses/ExpenseData';

interface Props {
  name: string;
  amount: number;
  expenses: Expense[];
}

const CommonCardPayment = ({ name, amount, expenses }: Props) => {
  const {
    expenseStore,
    expenseTypeStore,
    expenseCategoryStore,
    storeStore,
    paymentMethodStore,
  } = useStore();

  const [detailsVisible, setDetailsVisible] = useState(false);

  return (
    <>
      <div style={{ paddingLeft: '4px' }}>
        <h4>
          <b>{name}</b>
        </h4>
      </div>
      <Input
        type='text'
        style={{ marginRight: '6px', marginTop: '6px', marginBottom: '6px' }}
      >
        <input value={currencyFormatter(amount)} readOnly={true} />
      </Input>
      <Button as='div' labelPosition='left'>
        <Label
          as='div'
          basic
          pointing='right'
          onClick={() => {
            setDetailsVisible(!detailsVisible);
          }}
        >
          {expenses.length} Expenses
        </Label>
        <Button
          icon
          onClick={() => {
            setDetailsVisible(!detailsVisible);
          }}
        >
          Details{' '}
          <Icon
            name={
              detailsVisible
                ? 'arrow alternate circle up'
                : 'arrow alternate circle down'
            }
          />
        </Button>
      </Button>
      {detailsVisible && (
        <Segment>
          <GenericItemList
            store={expenseStore}
            columns={getExpenseDisplayFieldProps(
              expenseStore,
              expenseTypeStore,
              expenseCategoryStore,
              storeStore,
              paymentMethodStore
            )}
            items={expenses}
            otherTitle='Expense'
            className='tablet or lower hidden'
            noEdit={true}
            noDelete={true}
          />
        </Segment>
      )}
    </>
  );
};

export default CommonCardPayment;
