import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Grid, Header, Image, Segment, Transition } from 'semantic-ui-react';

import { useStore } from '../../stores/Store';
import LoginForm from '../users/LoginForm';
import classes from './HomePage.module.css';

const HomePage = () => {
  const { userStore, modalStore } = useStore();
  const history = useHistory();
  const [state, setState] = useState({ animation: 'fade', duration: 1500, visible: false });

  useEffect(() => {
    if (userStore.isLoggedIn) {
      if (userStore.isDarshi) {
        setTimeout(() => {
          // return <Redirect to='/home' />;
          history.push('/home');
        }, 3000);
      } else {
        history.push('/home');
      }
    }
  }, [history, userStore.isDarshi, userStore.isLoggedIn]);

  useEffect(() => {
    if (userStore.isLoggedIn && !state.visible && state.animation === 'fade') {
      setTimeout(() => {
        setState({ animation: 'drop', duration: 1480, visible: true });
        // console.log('drop');
      }, 1);
    }
  }, [history, state.animation, state.visible, userStore.isLoggedIn]);

  useEffect(() => {
    if (userStore.isLoggedIn && state.visible && state.animation !== 'jiggle') {
      setTimeout(() => {
        setState({ animation: 'jiggle', duration: 1000, visible: false });
        setState({ animation: 'jiggle', duration: 1000, visible: true });
        console.log('jiggle');
      }, 1510);
    }
  }, [history, state.animation, state.visible, userStore.isLoggedIn]);

  if (userStore.isLoggedIn) {
    return (
      <Grid columns={1}>
        <Grid.Column>
          <Transition.Group animation={state.animation} duration={state.duration}>
            {state.visible && <Image centered size='huge' src='/assets/kiss-emoji.svg' />}
          </Transition.Group>
        </Grid.Column>
      </Grid>
    );
    // return <Redirect to='/home' />;
  }

  return (
    <Segment inverted textAlign='center' vertical className={classes.masthead}>
      <div style={{ width: '100%' }}>
        <Header as='h1' inverted>
          <Image size='massive' src='/assets/logo.png' alt='logo' style={{ marginBottom: 12 }} />
          Ashi Accounts
        </Header>
        {userStore.isLoggedIn ? (
          <>
            <Header as='h2' inverted content='Welcome to Ashi Accounts' />
            <Button as={Link} to='/home' size='huge' inverted>
              Go to Accounts
            </Button>
          </>
        ) : (
          <>
            <Button size='huge' inverted onClick={() => modalStore.openModal(<LoginForm />)}>
              Login
            </Button>
          </>
        )}
      </div>
    </Segment>
  );
};

export default observer(HomePage);
