import { useStore } from '../../../stores/Store';
import GenericDataList from '../GenericDataList'; 
import { IncomeType } from '../../../models/income-type';

const IncomeTypeList = () => {
  const { incomeTypeStore } = useStore();

  return (
    <GenericDataList<IncomeType>
      store={incomeTypeStore}
      displayProp='incomeTypeValue'
      title='Income Types'
      editTitle='Income Type'
      colTitle='Income Type Value'
      addButtonTitle='Add Income Type'
    />
  );
};

export default IncomeTypeList;
