import { useStore } from '../../../stores/Store';
import GenericDataList from '../GenericDataList'; 
import { SpecialExpenseType } from '../../../models/special-expense-type';

const SpecialExpenseTypeList = () => {
  const { specialExpenseTypeStore } = useStore();

  return (
    <GenericDataList<SpecialExpenseType>
      store={specialExpenseTypeStore}
      displayProp='typeName'
      title='Special Expense Types'
      editTitle='Special Expense Type'
      colTitle='Type Name'
      addButtonTitle='Add Special Expense Type'
    />
  );
};

export default SpecialExpenseTypeList;
