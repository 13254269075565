import { Year } from '../models/year';
import { StoreBase } from './StoreBase';

export default class YearStore extends StoreBase<Year> {
  constructor() {
    super('Years');
  }

  protected sortItems = (items: Year[]) => {
    return items.sort(({ yearValue: a }, { yearValue: b }) => {
      return a > b ? 1 : a < b ? -1 : 0;
    });
  };

  protected getDisplayText = (item: Year) => {
    return `${item.yearValue}`;
  };
}
