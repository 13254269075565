import { SpecialExpenseType } from '../models/special-expense-type';
import { StoreBase } from './StoreBase';

export default class SpecialExpenseTypeStore extends StoreBase<SpecialExpenseType> {
  constructor() {
    super('SpecialExpenseTypes');
  }

  protected sortItems = (items: SpecialExpenseType[]) => {
    return items.sort(({ typeName: a }, { typeName: b }) => {
      return a > b ? 1 : a < b ? -1 : 0;
    });
  };

  protected getDisplayText = (item: SpecialExpenseType) => {
    return item.typeName;
  };
}
