import { observer } from 'mobx-react-lite';
import { Card, Container, Header } from 'semantic-ui-react';

import PeopleSummaryByYearDto from '../../models/people-summary-by-year-dto';
import { Person } from '../../stores/IncomeExpenseStore';
import YearSummary from './YearSummary';

interface Props {
  title: string;
  data: PeopleSummaryByYearDto[];
  person: Person;
}

const YearlySummaries = ({ title, data, person }: Props) => {
  return (
    <Container fluid>
      <Header as='h2'>{title}</Header>
      <Card.Group>
        {data.map((x) => (
          <YearSummary key={x.year} summary={x} person={person} />
        ))}
      </Card.Group>
    </Container>
  );
};

export default observer(YearlySummaries);
