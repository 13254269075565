import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { SyntheticEvent } from 'react';
import { Grid, Input } from 'semantic-ui-react';
import { getDisplayDate } from '../../common/helpers/my-date-formatter';
import { Expense } from '../../models/expense';
import GenericItemList from '../../pages/base/GenericItemList';
import { useStore } from '../../stores/Store';
import ExpenseCard from '../expenses/ExpenseCard';
import {
  getExpenseDeleteWarningMessage,
  getExpenseDisplayFieldProps,
} from '../expenses/ExpenseData';

interface Props {
  extraExpenses: Expense[];
}

const ExtraExpenses = ({ extraExpenses }: Props) => {
  const {
    expenseStore,
    expenseTypeStore,
    expenseCategoryStore,
    storeStore,
    paymentMethodStore,
  } = useStore();

  const columns = getExpenseDisplayFieldProps(
    expenseStore,
    expenseTypeStore,
    expenseCategoryStore,
    storeStore,
    paymentMethodStore
  );

  const [searchText, setSearchText] = useState('');

  const filteredExtraExpenses = () => {
    if (searchText.length === 0) {
      return extraExpenses;
    }
    const txt = searchText.toLowerCase();
    return extraExpenses.filter(
      (x) =>
        `${x.amount}`.includes(txt) ||
        x.expenseCategory?.expenseCategoryValue.toLowerCase().includes(txt) ||
        getDisplayDate(x.expenseDate).toLowerCase().includes(txt) ||
        x.expenseType?.typeName.toLowerCase().includes(txt) ||
        x.paymentMethod?.methodName.toLowerCase().includes(txt) ||
        x.store?.storeName.toLowerCase().includes(txt) ||
        x.description?.toLowerCase().includes(txt)
    );
  };

  return (
    <>
      <Grid stackable>
        <Grid.Column floated='right' width={8} className='search-text'>
          <Input
            icon='search'
            placeholder='Search...'
            value={searchText}
            onChange={(e: SyntheticEvent<HTMLInputElement>) => {
              setSearchText(e.currentTarget.value);
            }}
          />
        </Grid.Column>
      </Grid>
      <GenericItemList
        store={expenseStore}
        columns={columns}
        items={filteredExtraExpenses()}
        otherTitle='Expense'
        getDeleteWarningMessage={getExpenseDeleteWarningMessage}
        className='tablet or lower hidden'
      />
      <Grid className='mobile tablet only'>
        {filteredExtraExpenses().map((expense: Expense) => (
          <Grid.Row key={expense.id}>
            <Grid.Column>
              <ExpenseCard expense={expense} />
            </Grid.Column>
          </Grid.Row>
        ))}
      </Grid>
    </>
  );
};

export default observer(ExtraExpenses);
