import { ExpenseCategory } from '../models/expense-category';
import { StoreBase } from './StoreBase';

export default class ExpenseCategoryStore extends StoreBase<ExpenseCategory> {
  constructor() {
    super('ExpenseCategories');
  }

  protected sortItems = (items: ExpenseCategory[]) => {
    return items.sort(({ expenseCategoryValue: a }, { expenseCategoryValue: b }) => {
      return a > b ? 1 : a < b ? -1 : 0;
    });
  };

  protected getDisplayText = (item: ExpenseCategory) => {
    return item.expenseCategoryValue;
  };
}
