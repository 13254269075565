import { useEffect } from 'react';
import MySelectInput from '../common/form/MySelectInput';
import { ModelBase } from '../models/model-base';
import { StoreBase } from '../stores/StoreBase';

interface Props<T extends ModelBase> {
  store: StoreBase<T>;
  placeholder: string;
  name: string;
  label?: string;
  changeHandler?: (id: any) => void;
}

const AppSelectInput = <T extends ModelBase>({
  store,
  placeholder,
  name,
  label,
  changeHandler,
}: Props<T>) => {
  const { itemsForSelectList, loadItemsIfNeeded } = store;

  useEffect(() => {
    loadItemsIfNeeded();
  }, [loadItemsIfNeeded]);

  return (
    <MySelectInput
      name={name}
      placeholder={placeholder}
      label={label}
      options={itemsForSelectList}
      changeHandler={changeHandler}
    />
  );
};

export default AppSelectInput;
