import { observer } from 'mobx-react-lite';
import { Card } from 'semantic-ui-react';

import PeopleSummaryByYearDto from '../../models/people-summary-by-year-dto';
import { getBackColor, getColor, Person, toCurrency } from '../../stores/IncomeExpenseStore';

interface Props {
  summary: PeopleSummaryByYearDto;
  person: Person;
}

const YearSummary = ({ summary, person }: Props) => {
  const getData = () => {
    if (person === Person.asanga) {
      return {
        income: summary.incomes.incomeAsanga,
        expenses: summary.expenses.expensesAsanga,
        savings: summary.savingsAsanga,
      };
    } else if (person === Person.darshi) {
      return {
        income: summary.incomes.incomeDarshi,
        expenses: summary.expenses.expensesDarshi,
        savings: summary.savingsDarshi,
      };
    } else if (person === Person.common) {
      return {
        income: summary.incomes.incomeCommon,
        expenses: summary.expenses.expensesCommon,
        savings: summary.savingsCommon,
      };
    }
  };

  const data = getData();
  if (!data) {
    return null;
  }

  return (
    <Card style={{ backgroundColor: getBackColor(data.savings) }}>
      <Card.Content>
        <Card.Header>{summary.year}</Card.Header>
        <Card.Description>
          <p>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <div>Income:</div>
              <div style={{ marginLeft: 'auto' }}>{toCurrency(data.income)}</div>
            </div>
          </p>
          <p>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <div>Expenses:</div>
              <div style={{ marginLeft: 'auto' }}>{toCurrency(data.expenses)}</div>
            </div>
          </p>
          <p>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <div>Savings:</div>
              <div style={{ marginLeft: 'auto', color: getColor(data.savings) }}>{toCurrency(data.savings)}</div>
            </div>
          </p>
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

export default observer(YearSummary);
