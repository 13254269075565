import { getDisplayDate } from '../../common/helpers/my-date-formatter';
import { SpecialExpense } from '../../models/special-expense';
import { useStore } from '../../stores/Store';
import GenericList from '../base/GenericList';

const SpecialExpenseList = () => {
  const {
    specialExpenseStore,
    specialExpenseTypeStore,
    specialExpensePaymentMethodStore,
  } = useStore();

  const getDeleteWarningMessage = (specialExpense: SpecialExpense) => {
    return `Are you sure you want to delete the SpecialExpense of ${
      specialExpense.type?.typeName
    } made on ${getDisplayDate(
      new Date(specialExpense.expenseDate)
    )} to the amount of ${specialExpense.amount} ?`;
  };

  const getNewItem = () => {
    const specialExpense: SpecialExpense = {
      id: 0,
      amount: 0,
      expenseDate: new Date(),
    };
    return specialExpense;
  };

  return (
    <GenericList
      store={specialExpenseStore}
      title='Special Expenses'
      otherTitle='Special Expense'
      columns={[
        {
          dataType: 'DateInput',
          placeholder: 'Expense Date',
          propName: 'expenseDate',
          required: true,
          requiredMsg: 'Expense Date is required',
          sorted: 'descending',
        },
        {
          dataType: 'SelectInput',
          displayPropName: 'type.typeName',
          store: specialExpenseTypeStore,
          placeholder: 'Type',
          propName: 'typeId',
          required: true,
          requiredMsg: 'Type is required',
        },
        {
          dataType: 'SelectInput',
          displayPropName: 'paymentMethod.method',
          store: specialExpensePaymentMethodStore,
          placeholder: 'Payment Method',
          propName: 'paymentMethodId',
          required: true,
          requiredMsg: 'Payment Method is required',
        },
        {
          dataType: 'CurrencyInput',
          placeholder: 'Amount',
          propName: 'amount',
          required: true,
          requiredMsg: 'Amount is required',
        },
        {
          dataType: 'TextArea',
          placeholder: 'Description',
          propName: 'description',
          required: false,
        },
      ]}
      getDeleteWarningMessage={getDeleteWarningMessage}
      getNewItem={getNewItem}
    />
  );
};

export default SpecialExpenseList;
