import { observer } from 'mobx-react-lite';
import React from 'react';
import { getDisplayDate } from '../../common/helpers/my-date-formatter';
import { Gas } from '../../models/gas';
import { useStore } from '../../stores/Store';
import GenericList from '../base/GenericList';

const GasList = () => {
  const { gasStore, carStore, userStore } = useStore();

  const getDeleteWarningMessage = (gas: Gas) => {
    return `Are you sure you want to delete the Gas Expense made on ${getDisplayDate(
      new Date(gas.gasDate)
    )} to the amount of ${gas.amount} ?`;
  };

  const getNewItem = () => {
    const Fit = 4;
    const RAV4 = 1;
    const knownAs = userStore.user?.knownAs;
    const carId = knownAs ? (knownAs === 'Asanga' ? Fit : RAV4) : undefined;
    const gas: Gas = {
      id: 0,
      amount: undefined,
      gasDate: new Date(),
      carId: carId,
    };
    return gas;
  };

  return (
    <GenericList
      store={gasStore}
      title='Gas Expenses'
      otherTitle='Gas Expense'
      columns={[
        {
          dataType: 'DateInput',
          placeholder: 'Date',
          propName: 'gasDate',
          required: true,
          requiredMsg: 'Date is required',
          sorted: 'descending',
        },
        {
          dataType: 'SelectInput',
          displayPropName: 'car.carName',
          store: carStore,
          placeholder: 'Car',
          propName: 'carId',
          required: true,
          requiredMsg: 'Car is required',
        },
        {
          dataType: 'CurrencyInput',
          placeholder: 'Amount',
          propName: 'amount',
          required: true,
          requiredMsg: 'Amount is required',
        },
        {
          dataType: 'NumberInput',
          placeholder: 'Price per liter',
          propName: 'pricePerLiter',
          required: true,
        },
        {
          dataType: 'NumberInput',
          placeholder: 'Odometer',
          propName: 'odometer',
          required: false,
        },
      ]}
      getDeleteWarningMessage={getDeleteWarningMessage}
      getNewItem={getNewItem}
    />
  );
};

export default observer(GasList);
