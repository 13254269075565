import { observer } from 'mobx-react-lite';
import { Card } from 'semantic-ui-react';

import PeopleSummaryByMonthDto from '../../models/people-summary-by-month-dto';
import { getBackColor, getColor, Person, toCurrency } from '../../stores/IncomeExpenseStore';

interface Props {
  summary: PeopleSummaryByMonthDto;
  person: Person;
}

const MonthSummary = ({ summary, person }: Props) => {
  const getData = () => {
    if (person === Person.asanga) {
      return {
        income: summary.incomes.incomeAsanga,
        expenses: summary.expenses.expensesAsanga,
        savings: summary.savingsAsanga,
      };
    } else if (person === Person.darshi) {
      return {
        income: summary.incomes.incomeDarshi,
        expenses: summary.expenses.expensesDarshi,
        savings: summary.savingsDarshi,
      };
    } else if (person === Person.common) {
      return {
        income: summary.incomes.incomeCommon,
        expenses: summary.expenses.expensesCommon,
        savings: summary.savingsCommon,
      };
    }
  };

  const data = getData();
  if (!data) {
    return null;
  }

  return (
    <Card style={{ backgroundColor: getBackColor(data.savings) }}>
      <Card.Content>
        <Card.Header>{summary.name}</Card.Header>
        <Card.Description>
          <p>
            <span style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <span>Income:</span>
              <span style={{ marginLeft: 'auto' }}>{toCurrency(data.income)}</span>
            </span>
          </p>
          <p>
            <span style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <span>Expenses:</span>
              <span style={{ marginLeft: 'auto' }}>{toCurrency(data.expenses)}</span>
            </span>
          </p>
          <p>
            <span style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <span>Savings:</span>
              <span style={{ marginLeft: 'auto', color: getColor(data.savings) }}>{toCurrency(data.savings)}</span>
            </span>
          </p>
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

export default observer(MonthSummary);
