import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { CheckboxProps, Form } from 'semantic-ui-react';

import LoadingComponent from '../../layout/LoadingComponent';
import { AshiChart, ChartType, Person } from '../../stores/IncomeExpenseStore';
import { useStore } from '../../stores/Store';

interface Props {
  showTotal: boolean;
}

const YearlySavingsChart = ({ showTotal }: Props) => {
  const { incomeExpenseStore } = useStore();
  const {
    loading,
    loadYearly,
    allYearlySavingsData,
    allYearlySavingsDataCumalative,
    changeChartType,
    getChartType,
    getVisibility,
    changeVisibility,
  } = incomeExpenseStore;

  useEffect(() => {
    loadYearly();
  }, [loadYearly]);

  const handleChartTypeChange = (e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) =>
    changeChartType(
      showTotal ? AshiChart.allCumulativeYearlySummaries : AshiChart.allYearlySummaries,
      data.value! as ChartType
    );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'All Yearly Savings',
      },
    },
  };

  const chart = showTotal ? AshiChart.allCumulativeYearlySummaries : AshiChart.allYearlySummaries;
  const chartType = getChartType(chart);

  const asangaVisible = getVisibility(chart, Person.asanga);
  const darshiVisible = getVisibility(chart, Person.darshi);
  const commonVisible = getVisibility(chart, Person.common);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <Form>
        <Form.Group inline>
          <label>Chart Type</label>
          <Form.Radio
            label='Line'
            value={ChartType.lineChart}
            checked={chartType === ChartType.lineChart}
            onChange={handleChartTypeChange}
          />
          <Form.Radio
            label='Bar'
            value={ChartType.barChart}
            checked={chartType === ChartType.barChart}
            onChange={handleChartTypeChange}
          />
        </Form.Group>
        <Form.Group inline>
          <label>Show</label>
          <Form.Checkbox
            label='Asanga'
            checked={asangaVisible}
            onChange={(e, data) => changeVisibility(chart, Person.asanga, data.checked ?? false)}
          />
          <Form.Checkbox
            label='Darshi'
            checked={darshiVisible}
            onChange={(e, data) => changeVisibility(chart, Person.darshi, data.checked ?? false)}
          />
          <Form.Checkbox
            label='Common'
            checked={commonVisible}
            onChange={(e, data) => changeVisibility(chart, Person.common, data.checked ?? false)}
          />
        </Form.Group>
      </Form>
      {chartType === ChartType.barChart && (
        <Bar options={options} data={showTotal ? allYearlySavingsDataCumalative : allYearlySavingsData} />
      )}
      {chartType === ChartType.lineChart && (
        <Line options={options} data={showTotal ? allYearlySavingsDataCumalative : allYearlySavingsData} />
      )}
    </>
  );
};

export default observer(YearlySavingsChart);
