import { CarsLink } from '../models/cars-link';
import { PagedStoreBase } from './PagedStoreBase';

export default class CarsLinkStore extends PagedStoreBase<CarsLink> {
  constructor() {
    super('CarsLinks');
  }

  protected sortItems = (items: CarsLink[]) => {
    return items.sort(({ id: a }, { id: b }) => {
      return a > b ? 1 : a < b ? -1 : 0;
    });
  };

}
