import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { CheckboxProps, Form } from 'semantic-ui-react';

import LoadingComponent from '../../layout/LoadingComponent';
import { AshiChart, ChartType, Person } from '../../stores/IncomeExpenseStore';
import { useStore } from '../../stores/Store';

interface Props {
  showTotal: boolean;
  year?: number;
  last12Months?: boolean;
}

const MonthlySavingsChart = ({ showTotal, year, last12Months }: Props) => {
  const { incomeExpenseStore } = useStore();
  const {
    loading,
    loadMonthly,
    allMonthlySavingsData,
    allMonthlySavingsDataCumalative,
    changeChartType,
    getChartType,
    getVisibility,
    changeVisibility,
    getMonthlySavingsDataForYear,
    getMonthlySavingsDataForYearCumalative,
    monthlySavingsDataForLast12Months,
    monthlySavingsDataForLast12MonthsCumalative,
  } = incomeExpenseStore;

  useEffect(() => {
    loadMonthly();
  }, [loadMonthly]);

  if (loading) {
    return <LoadingComponent />;
  }

  const chart = last12Months
    ? showTotal
      ? AshiChart.cumulativeSummariesForLast12Months
      : AshiChart.summariesForLast12Months
    : !!year
    ? showTotal
      ? AshiChart.monthlyCumulativeSummariesForYear
      : AshiChart.monthlySummariesForYear
    : showTotal
    ? AshiChart.allCumulativeMonthlySummaries
    : AshiChart.allMonthlySummaries;

  const handleChartTypeChange = (e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    changeChartType(chart, data.value! as ChartType);
  };

  const titleSuffix = !!year ? ` for ${year}` : last12Months ? ' for last 12 months' : '';
  const titlePrefix = !showTotal && !year && !last12Months ? 'All ' : '';

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: titlePrefix + (showTotal ? 'Total Savings' : 'Monthly Savings') + titleSuffix,
      },
    },
  };

  const chartType = getChartType(chart);

  const asangaVisible = getVisibility(chart, Person.asanga);
  const darshiVisible = getVisibility(chart, Person.darshi);
  const commonVisible = getVisibility(chart, Person.common);

  let data: any;
  if (!!year) {
    data = showTotal ? getMonthlySavingsDataForYearCumalative(year) : getMonthlySavingsDataForYear(year);
  } else if (last12Months) {
    data = showTotal ? monthlySavingsDataForLast12MonthsCumalative : monthlySavingsDataForLast12Months;
  } else {
    data = showTotal ? allMonthlySavingsDataCumalative : allMonthlySavingsData;
  }

  return (
    <>
      <Form>
        <Form.Group inline>
          <label>Chart Type</label>
          <Form.Radio
            label='Line'
            value={ChartType.lineChart}
            checked={chartType === ChartType.lineChart}
            onChange={handleChartTypeChange}
          />
          <Form.Radio
            label='Bar'
            value={ChartType.barChart}
            checked={chartType === ChartType.barChart}
            onChange={handleChartTypeChange}
          />
        </Form.Group>
        <Form.Group inline>
          <label>Show</label>
          <Form.Checkbox
            label='Asanga'
            checked={asangaVisible}
            onChange={(e, data) => changeVisibility(chart, Person.asanga, data.checked ?? false)}
          />
          <Form.Checkbox
            label='Darshi'
            checked={darshiVisible}
            onChange={(e, data) => changeVisibility(chart, Person.darshi, data.checked ?? false)}
          />
          <Form.Checkbox
            label='Common'
            checked={commonVisible}
            onChange={(e, data) => changeVisibility(chart, Person.common, data.checked ?? false)}
          />
        </Form.Group>
      </Form>
      {chartType === ChartType.lineChart && <Line options={options} data={data} />}
      {chartType === ChartType.barChart && <Bar options={options} data={data} />}
    </>
  );
};

export default observer(MonthlySavingsChart);
