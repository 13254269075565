import { useStore } from '../../../stores/Store';
import GenericDataList from '../GenericDataList'; 
import { Store } from '../../../models/store';

const StoreList = () => {
  const { storeStore } = useStore();

  return (
    <GenericDataList<Store>
      store={storeStore}
      displayProp='storeName'
      title='Stores'
      editTitle='Store'
      colTitle='Store Name'
      addButtonTitle='Add Store'
    />
  );
};

export default StoreList;
