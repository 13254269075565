import { Expense } from '../../models/expense';
import { useStore } from '../../stores/Store';
import GenericList from '../base/GenericList';
import { Grid } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import ExpenseCard from '../../controls/expenses/ExpenseCard';
import {
  getExpenseDeleteWarningMessage,
  getExpenseDisplayFieldProps,
} from '../../controls/expenses/ExpenseData';

const ExpenseList = () => {
  const {
    expenseStore,
    expenseTypeStore,
    expenseCategoryStore,
    storeStore,
    paymentMethodStore,
  } = useStore();

  const getNewItem = () => {
    const expense = new Expense();
    return expense;
  };

  const columns = getExpenseDisplayFieldProps(
    expenseStore,
    expenseTypeStore,
    expenseCategoryStore,
    storeStore,
    paymentMethodStore
  );

  return (
    <>
      <GenericList
        className='tablet or lower hidden'
        store={expenseStore}
        title='Expenses'
        otherTitle='Expense'
        columns={columns}
        getDeleteWarningMessage={getExpenseDeleteWarningMessage}
        getNewItem={getNewItem}
      />
      <Grid className='mobile tablet only'>
        {expenseStore.items.map((expense: Expense) => (
          <Grid.Row key={expense.id}>
            <Grid.Column>
              <ExpenseCard expense={expense} />
            </Grid.Column>
          </Grid.Row>
        ))}
      </Grid>
      {/* <Card.Group className='mobile tablet only'>
        {expenseStore.items.map((expense: Expense) => (
          <Card key={expense.id}>
            <Card.Content>
              <Statistic floated='right' size='mini'>
                <Statistic.Value>
                  {currencyFormatter(expense.amount)}
                </Statistic.Value>
              </Statistic>
              <Card.Header>
                {getDisplayDate(expense.expenseDate)}
              </Card.Header>
              <Card.Header style={{ paddingTop: '5px', fontSize: 'medium' }}>
                {expense.expenseType?.typeName}
              </Card.Header>
              <Card.Meta>
                {expense.expenseCategory?.expenseCategoryValue} -{' '}
                {expense.store?.storeName}
              </Card.Meta>
              <Card.Meta>{expense.paymentMethod?.methodName}</Card.Meta>
              <Card.Description>{expense.description}</Card.Description>
            </Card.Content>
            <Card.Content extra>
              <div className='ui two buttons'>
                <Button
                  basic
                  content='Edit'
                  color='green'
                  icon='edit'
                  labelPosition='left'
                />
                <Button
                  basic
                  content='Delete'
                  color='red'
                  icon='trash alternate outline'
                  labelPosition='left'
                />
              </div>
            </Card.Content>
          </Card>
        ))}
      </Card.Group> */}
    </>
  );
};

export default observer(ExpenseList);
