import { ExpenseCategory } from './expense-category';
import { ExpenseType } from './expense-type';
import { PaymentMethod } from './payment-method';
import { Store } from './store';

export class Expense {
  id: number;
  expenseDate: Date;
  expenseTypeId?: number;
  expenseType?: ExpenseType;
  expenseCategoryId?: number;
  expenseCategory?: ExpenseCategory;
  storeId?: number;
  store?: Store;
  paymentMethodId?: number;
  paymentMethod?: PaymentMethod;
  amount?: number;
  description?: string;

  expenseCategoryIdAuto?: boolean;
  storeIdAuto?: boolean;
  paymentMethodIdAuto?: boolean;
  amountAuto?: boolean;
  descriptionAuto?: boolean;

  constructor() {
    this.id = 0;
    this.expenseDate = new Date();
  }

  get canSetExpenseCategory() {
    if (this.expenseCategoryIdAuto !== undefined) {
      return this.expenseCategoryIdAuto;
    }
    return this.expenseCategoryId === undefined;
  }

  get canSetStore() {
    if (this.storeIdAuto !== undefined) {
      return this.storeIdAuto;
    }
    return this.storeId === undefined;
  }

  get canSetPaymentMethod() {
    if (this.paymentMethodIdAuto !== undefined) {
      return this.paymentMethodIdAuto;
    }
    return this.paymentMethodId === undefined;
  }

  get canSetAmount() {
    if (this.amountAuto !== undefined) {
      return this.amountAuto;
    }
    return this.amount === undefined;
  }

  get canSetDescription() {
    if (this.descriptionAuto !== undefined) {
      return this.descriptionAuto;
    }
    return this.description === undefined;
  }
}
