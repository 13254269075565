import { EducationExpenseType } from '../models/education-expense-type';
import { StoreBase } from './StoreBase';

export default class EducationExpenseTypeStore extends StoreBase<EducationExpenseType> {
  constructor() {
    super('EducationExpenseTypes');
  }

  protected sortItems = (items: EducationExpenseType[]) => {
    return items.sort(({ expenseName: a }, { expenseName: b }) => {
      return a > b ? 1 : a < b ? -1 : 0;
    });
  };

  protected getDisplayText = (item: EducationExpenseType) => {
    return item.expenseName;
  };
}
