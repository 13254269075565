import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import agent from '../api/agent';
import { AutoPayment, AutoPaymentDto } from '../models/auto-payment';
import { StoreBase } from './StoreBase';

export default class AutoPaymentStore extends StoreBase<AutoPayment> {
  monthsRegistry = new Map<number, string>();
  paymentsRegistry = new Map<number, AutoPaymentDto>();
  currentYear?: number;
  currentMonth?: number;

  constructor() {
    super('AutoPayments');

    makeObservable(this, {
      monthsRegistry: observable,
      paymentsRegistry: observable,
      loadMonths: action,
      loadPaymentsForMonth: action,
      processPayments: action,
      toggleProcess: action,
      toggleDelete: action,
      monthsForSelectList: computed,
      payments: computed,
      canProcess: computed,
    });
  }

  get monthsForSelectList() {
    const items = Array.from(this.monthsRegistry.keys()).sort((a, b) => (a > b ? 1 : a < b ? -1 : 0) * -1);
    if (items && items.length > 0) {
      const retItems: { text: string; value: number }[] = [];
      items.forEach((x) => retItems.push({ text: this.monthsRegistry.get(x) ?? '', value: x }));
      return retItems;
    } else {
      return [];
    }
  }

  get payments() {
    const items = Array.from(this.paymentsRegistry.values()).sort(({ paymentDay: a }, { paymentDay: b }) =>
      a > b ? 1 : a < b ? -1 : 0
    );
    return items;
  }

  get canProcess() {
    return this.payments.some((x) => x.process || x.delete);
  }

  protected sortItems = (items: AutoPayment[]) => {
    return items.sort(({ amount: a }, { amount: b }) => {
      return a > b ? 1 : a < b ? -1 : 0;
    });
  };

  toggleProcess = (payment: AutoPaymentDto) => {
    payment.process = !payment.process;
  };

  toggleDelete = (payment: AutoPaymentDto) => {
    payment.delete = !payment.delete;
  };

  loadMonths = async () => {
    this.setLoading(true);
    try {
      runInAction(() => {
        this.monthsRegistry.clear();
      });
      const items = await agent.AutoPayments.getMonths();
      runInAction(() => {
        items.forEach((item) => {
          this.monthsRegistry.set(item.sortDate, item.month);
        });
      });
    } catch (error: any) {
      console.log(error);
      toast.error(error.message);
    } finally {
      this.setLoading(false);
    }
  };

  loadPaymentsForMonth = async (year: number, month: number) => {
    this.setLoading(true);
    try {
      runInAction(() => {
        this.currentYear = year;
        this.currentMonth = month;
        this.paymentsRegistry.clear();
      });
      const items = await agent.AutoPayments.getForMonth(year, month);
      runInAction(() => {
        items.forEach((item) => {
          if (item.paidId <= 0) {
            item.process = true;
          }
          this.paymentsRegistry.set(item.id, item);
        });
      });
    } catch (error: any) {
      console.log(error);
      toast.error(error.message);
    } finally {
      this.setLoading(false);
    }
  };

  processPayments = async () => {
    const items = this.payments.filter((x) => x.process || x.delete);
    if (items.length > 0) {
      this.setLoading(true);
      try {
        const ok = await agent.AutoPayments.process(items);
        if (ok) {
          toast.success('Auto patments made successfully');
        }
        this.loadPaymentsForMonth(this.currentYear ?? 0, this.currentMonth ?? 0);
      } catch (error: any) {
        console.log(error);
        toast.error(error.message);
      } finally {
        this.setLoading(false);
      }
    }
  };
}
