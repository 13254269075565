import { useStore } from '../../../stores/Store';
import GenericDataList from '../GenericDataList'; 
import { PaymentMethod } from '../../../models/payment-method';

const PaymentMethodList = () => {
  const { paymentMethodStore } = useStore();

  return (
    <GenericDataList<PaymentMethod>
      store={paymentMethodStore}
      displayProp='methodName'
      title='Payment Methods'
      editTitle='Payment Method'
      colTitle='Method Name'
      addButtonTitle='Add Payment Method'
    />
  );
};

export default PaymentMethodList;
