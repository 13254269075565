import { observer } from 'mobx-react-lite';
import { Modal } from 'semantic-ui-react';
import { useStore } from '../../stores/Store';

const ModalContainer = () => {
  const { modalStore } = useStore();

  return (
    <Modal
      open={modalStore.modal.open}
      onClose={modalStore.closeModal}
      size='mini'
      dimmer='blurring'
      closeOnDimmerClick={false}
    >
      <Modal.Content>{modalStore.modal.body}</Modal.Content>
    </Modal>
  );
};

export default observer(ModalContainer);
