import { createContext, useContext } from 'react';

import AsangaIncomeStore from './AsangaIncomeStore';
import AutoPaymentStore from './AutoPaymentStore';
import CardTransactionStore from './CardTransactionStore';
import CarExpenseStore from './CarExpenseStore';
import CarExpenseTypeStore from './CarExpenseTypeStore';
import CarsLinkStore from './CarsLinkStore';
import CarsSpecialLinkStore from './CarsSpecialLinkStore';
import CarStore from './CarStore';
import CarStoreStore from './CarStoreStore';
import ChildStore from './ChildStore';
import CommonStore from './CommonStore';
import EducationExpenseStore from './EducationExpenseStore';
import EducationExpenseTypeStore from './EducationExpenseTypeStore';
import EducationIncomeStore from './EducationIncomeStore';
import EducationIncomeTypeStore from './EducationIncomeTypeStore';
import ExpenseCategoryStore from './ExpenseCategoryStore';
import ExpenseStore from './ExpenseStore';
import ExpenseTypeStore from './ExpenseTypeStore';
import GasStore from './GasStore';
import IncomeExpenseStore from './IncomeExpenseStore';
import IncomePersonStore from './IncomePersonStore';
import IncomeStore from './IncomeStore';
import IncomeTypeStore from './IncomeTypeStore';
import ModalStore from './ModalStore';
import MonthStore from './MonthStore';
import PaymentMethodStore from './PaymentMethodStore';
import SpecialExpensePaymentMethodStore from './SpecialExpensePaymentMethodStore';
import SpecialExpenseStore from './SpecialExpenseStore';
import SpecialExpenseTypeStore from './SpecialExpenseTypeStore';
import SpecialIncomeStore from './SpecialIncomeStore';
import SpecialIncomeTypeStore from './SpecialIncomeTypeStore';
import StoreStore from './StoreStore';
import UserStore from './UserStore';
import YearStore from './YearStore';

interface Store {
  commonStore: CommonStore;
  userStore: UserStore;
  modalStore: ModalStore;
  cardTransactionStore: CardTransactionStore;
  asangaIncomeStore: AsangaIncomeStore;
  autoPaymentStore: AutoPaymentStore;
  carsLinkStore: CarsLinkStore;
  carsSpecialLinkStore: CarsSpecialLinkStore;
  childStore: ChildStore;
  educationExpenseStore: EducationExpenseStore;
  educationExpenseTypeStore: EducationExpenseTypeStore;
  educationIncomeStore: EducationIncomeStore;
  educationIncomeTypeStore: EducationIncomeTypeStore;
  expenseCategoryStore: ExpenseCategoryStore;
  expenseStore: ExpenseStore;
  expenseTypeStore: ExpenseTypeStore;
  incomePersonStore: IncomePersonStore;
  incomeStore: IncomeStore;
  incomeTypeStore: IncomeTypeStore;
  monthStore: MonthStore;
  paymentMethodStore: PaymentMethodStore;
  specialExpensePaymentMethodStore: SpecialExpensePaymentMethodStore;
  specialExpenseStore: SpecialExpenseStore;
  specialExpenseTypeStore: SpecialExpenseTypeStore;
  specialIncomeStore: SpecialIncomeStore;
  specialIncomeTypeStore: SpecialIncomeTypeStore;
  storeStore: StoreStore;
  yearStore: YearStore;
  carExpenseStore: CarExpenseStore;
  carExpenseTypeStore: CarExpenseTypeStore;
  carStore: CarStore;
  carStoreStore: CarStoreStore;
  gasStore: GasStore;
  incomeExpenseStore: IncomeExpenseStore;
}

export const store: Store = {
  commonStore: new CommonStore(),
  userStore: new UserStore(),
  modalStore: new ModalStore(),
  cardTransactionStore: new CardTransactionStore(),
  asangaIncomeStore: new AsangaIncomeStore(),
  autoPaymentStore: new AutoPaymentStore(),
  carsLinkStore: new CarsLinkStore(),
  carsSpecialLinkStore: new CarsSpecialLinkStore(),
  childStore: new ChildStore(),
  educationExpenseStore: new EducationExpenseStore(),
  educationExpenseTypeStore: new EducationExpenseTypeStore(),
  educationIncomeStore: new EducationIncomeStore(),
  educationIncomeTypeStore: new EducationIncomeTypeStore(),
  expenseCategoryStore: new ExpenseCategoryStore(),
  expenseStore: new ExpenseStore(),
  expenseTypeStore: new ExpenseTypeStore(),
  incomePersonStore: new IncomePersonStore(),
  incomeStore: new IncomeStore(),
  incomeTypeStore: new IncomeTypeStore(),
  monthStore: new MonthStore(),
  paymentMethodStore: new PaymentMethodStore(),
  specialExpensePaymentMethodStore: new SpecialExpensePaymentMethodStore(),
  specialExpenseStore: new SpecialExpenseStore(),
  specialExpenseTypeStore: new SpecialExpenseTypeStore(),
  specialIncomeStore: new SpecialIncomeStore(),
  specialIncomeTypeStore: new SpecialIncomeTypeStore(),
  storeStore: new StoreStore(),
  yearStore: new YearStore(),
  carExpenseStore: new CarExpenseStore(),
  carExpenseTypeStore: new CarExpenseTypeStore(),
  carStore: new CarStore(),
  carStoreStore: new CarStoreStore(),
  gasStore: new GasStore(),
  incomeExpenseStore: new IncomeExpenseStore(),
};

export const StoreContext = createContext(store);

export const useStore = () => {
  return useContext(StoreContext);
};
