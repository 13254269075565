import { observer } from 'mobx-react-lite';
import { Tab } from 'semantic-ui-react';

import { Person } from '../stores/IncomeExpenseStore';
import { useStore } from '../stores/Store';
import YearlySavingsChart from './charts/YearlySavingsChart';
import YearlySummaries from './summaries-display/YearlySummaries';

const YearlySummary = () => {
  const { incomeExpenseStore } = useStore();
  const { getName, allYearlySummaries } = incomeExpenseStore;

  const getDataTabPane = (person: Person) => {
    const data = allYearlySummaries;
    const title = 'Yearly Savings';
    return {
      menuItem: getName(person),
      render: () => <Tab.Pane>{<YearlySummaries title={title} data={data} person={person} />}</Tab.Pane>,
    };
  };

  const dataPanes = () => [getDataTabPane(Person.common), getDataTabPane(Person.asanga), getDataTabPane(Person.darshi)];

  const getTabPanes = () => {
    return [
      {
        menuItem: 'Chart',
        render: () => (
          <Tab.Pane>
            <YearlySavingsChart showTotal={false} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Data',
        render: () => (
          <Tab.Pane>
            <Tab panes={dataPanes()} />
          </Tab.Pane>
        ),
      },
    ];
  };

  return <Tab panes={getTabPanes()} />;
};

export default observer(YearlySummary);
