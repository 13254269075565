import { EducationExpense } from '../models/education-expense';
import { PagedStoreBase } from './PagedStoreBase';

export default class EducationExpenseStore extends PagedStoreBase<EducationExpense> {
  constructor() {
    super('EducationExpenses');
  }

  protected sortItems = (items: EducationExpense[]) => {
    return items.sort(({ expenseDate: ad }, { expenseDate: bd }) => {
      const a = ad.getTime();
      const b = bd.getTime();
      return b > a ? 1 : b < a ? -1 : 0;
    });
  };

  setItem = (item: EducationExpense) => {
    item.expenseDate = new Date(item.expenseDate);
    this.itemRegistry.set(item.id, item);
  };
}
