import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import agent from '../api/agent';
import PeopleSummaryBase from '../models/people-summary-base';
import PeopleSummaryByMonthDto from '../models/people-summary-by-month-dto';
import PeopleSummaryByYearDto from '../models/people-summary-by-year-dto';

export enum AshiChart {
  allMonthlySummaries = 0,
  allCumulativeMonthlySummaries,
  allYearlySummaries,
  allCumulativeYearlySummaries,
  monthlySummariesForYear,
  monthlyCumulativeSummariesForYear,
  summariesForLast12Months,
  cumulativeSummariesForLast12Months,
}

export enum Person {
  asanga = 0,
  darshi,
  common,
}

export enum ChartType {
  lineChart,
  barChart,
}

export class ChartOptions {
  people: boolean[] = [true, true, true];
  chartType: ChartType = ChartType.barChart;
}

export const toCurrency = (value: number) => {
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const getColor = (value: number) => {
  if (value >= 0) {
    return 'green';
  } else if (value < 0 && value > -500) {
    return 'orange';
  } else {
    return 'red';
  }
};

export const getBackColor = (value: number) => {
  if (value >= 0) {
    return '#befdc8';
  } else if (value < 0 && value > -500) {
    return '#fceca1';
  } else {
    return '#fdbfb8';
  }
};

export default class IncomeExpenseStore {
  loading: boolean = false;
  allMonthlySummaries: PeopleSummaryByMonthDto[] = [];
  allYearlySummaries: PeopleSummaryByYearDto[] = [];

  // One for each chart type
  chartOptions: ChartOptions[] = [
    new ChartOptions(),
    new ChartOptions(),
    new ChartOptions(),
    new ChartOptions(),
    new ChartOptions(),
    new ChartOptions(),
    new ChartOptions(),
    new ChartOptions(),
  ];

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (state: boolean) => {
    this.loading = state;
  };

  loadMonthly = async (reload: boolean = false) => {
    if (!reload && this.allMonthlySummaries.length > 0) {
      return;
    }

    this.setLoading(true);
    try {
      const items = await agent.IncomeExpense.getAllMonths();
      runInAction(() => {
        this.allMonthlySummaries = items.filter((x) => x.year !== 2014);
      });
    } catch (error: any) {
      console.log(error);
      toast.error(error.message);
    } finally {
      this.setLoading(false);
    }
  };

  loadYearly = async (reload: boolean = false) => {
    try {
      const items = await agent.IncomeExpense.getAllYears();
      runInAction(() => {
        this.allYearlySummaries = items.filter((x) => x.year !== 2014);
      });
    } catch (error: any) {
      console.log(error);
      toast.error(error.message);
    } finally {
      this.setLoading(false);
    }
  };

  changeVisibility = (chart: AshiChart, person: Person, visible: boolean) => {
    const data: ChartOptions = { ...this.chartOptions[chart] };
    data.people[person] = visible;
    this.chartOptions[chart] = data;
  };

  changeChartType = (chart: AshiChart, chartType: ChartType) => {
    const data: ChartOptions = { ...this.chartOptions[chart] };
    data.chartType = chartType;
    this.chartOptions[chart] = data;
  };

  getVisibility = (chart: AshiChart, person: Person) => {
    return this.chartOptions[chart].people[person];
  };

  getChartType = (chart: AshiChart) => {
    return this.chartOptions[chart].chartType;
  };

  private getMonthLabels = (data: PeopleSummaryByMonthDto[]) => {
    return data.map((x) => x.name);
  };

  private getYearLabels = (data: PeopleSummaryByYearDto[]) => {
    return data.map((x) => `${x.year}`);
  };

  get allMonthsChartLabels() {
    return this.getMonthLabels(this.allMonthlySummaries);
  }

  get allYearsChartLabels() {
    return this.getYearLabels(this.allYearlySummaries);
  }

  get monthlySummariesForLast12Months() {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1;
    return this.allMonthlySummaries.filter(
      (x) =>
        (x.year === currentYear - 1 && x.month >= currentMonth) || (x.year === currentYear && x.month < currentMonth)
    );
  }

  getMonthlySummariesForYear = (year: number) => {
    return this.allMonthlySummaries.filter((x) => x.year === year);
  };

  private getDataSet = (asangaData?: number[], darshiData?: number[], commonData?: number[]) => {
    const dataset = [];
    if (asangaData) {
      dataset.push({
        label: 'Asanga',
        data: asangaData,
        borderColor: 'rgb(25, 255, 132)',
        backgroundColor: 'rgba(25, 255, 132, 0.5)',
      });
    }
    if (darshiData) {
      dataset.push({
        label: 'Darshi',
        data: darshiData,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      });
    }
    if (commonData) {
      dataset.push({
        label: 'Common',
        data: commonData,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      });
    }
    return dataset;
  };

  private getDataSetForChart = (chart: AshiChart, year?: number) => {
    let dataToUse: PeopleSummaryBase[] = [];
    switch (chart) {
      case AshiChart.allMonthlySummaries:
      case AshiChart.allCumulativeMonthlySummaries:
        dataToUse = this.allMonthlySummaries.map((x) => x as PeopleSummaryBase);
        break;

      case AshiChart.allYearlySummaries:
      case AshiChart.allCumulativeYearlySummaries:
        dataToUse = this.allYearlySummaries.map((x) => x as PeopleSummaryBase);
        break;

      case AshiChart.monthlySummariesForYear:
      case AshiChart.monthlyCumulativeSummariesForYear:
        if (year) {
          dataToUse = this.allMonthlySummaries.filter((x) => x.year === year).map((x) => x as PeopleSummaryBase);
        }
        break;

      case AshiChart.summariesForLast12Months:
      case AshiChart.cumulativeSummariesForLast12Months:
        dataToUse = this.monthlySummariesForLast12Months.map((x) => x as PeopleSummaryBase);
        break;
    }

    const asangaVisible = this.getVisibility(chart, Person.asanga);
    const darshiVisible = this.getVisibility(chart, Person.darshi);
    const commonVisible = this.getVisibility(chart, Person.common);
    const cumulative =
      chart === AshiChart.allCumulativeMonthlySummaries ||
      chart === AshiChart.allCumulativeYearlySummaries ||
      chart === AshiChart.monthlyCumulativeSummariesForYear ||
      chart === AshiChart.cumulativeSummariesForLast12Months;

    const asangaData = asangaVisible
      ? dataToUse.map((x, index, array) => {
          if (cumulative) {
            return index === 0 ? x.savingsAsanga : x.savingsAsanga + array[index - 1].savingsAsanga;
          } else {
            return x.savingsAsanga;
          }
        })
      : undefined;
    const darshiData = darshiVisible
      ? dataToUse.map((x, index, array) => {
          if (cumulative) {
            return index === 0 ? x.savingsDarshi : x.savingsDarshi + array[index - 1].savingsDarshi;
          } else {
            return x.savingsDarshi;
          }
        })
      : undefined;
    const commonData = commonVisible
      ? dataToUse.map((x, index, array) => {
          if (cumulative) {
            return index === 0 ? x.savingsCommon : x.savingsCommon + array[index - 1].savingsCommon;
          } else {
            return x.savingsCommon;
          }
        })
      : undefined;

    return this.getDataSet(asangaData, darshiData, commonData);
  };

  // get allMonthlySavingsDataSet() {
  //   const asangaData = this.getVisibility(AshiChart.allMonthlySummaries, Person.asanga)
  //     ? this.allMonthlySummaries.map((x, index, array) => {
  //         return x.savingsAsanga;
  //       })
  //     : undefined;
  //   const darshiData = this.getVisibility(AshiChart.allMonthlySummaries, Person.darshi)
  //     ? this.allMonthlySummaries.map((x, index, array) => {
  //         return x.savingsDarshi;
  //       })
  //     : undefined;
  //   const commonData = this.getVisibility(AshiChart.allMonthlySummaries, Person.common)
  //     ? this.allMonthlySummaries.map((x, index, array) => {
  //         return x.savingsCommon;
  //       })
  //     : undefined;

  //   return this.getDataSet(asangaData, darshiData, commonData);
  // }

  // get allMonthlySavingsCumalativeDataSet() {
  //   const asangaData = this.getVisibility(AshiChart.allCumulativeMonthlySummaries, Person.asanga)
  //     ? this.allMonthlySummaries.map((x, index, array) =>
  //         index === 0 ? x.savingsAsanga : x.savingsAsanga + array[index - 1].savingsAsanga
  //       )
  //     : undefined;
  //   const darshiData = this.getVisibility(AshiChart.allCumulativeMonthlySummaries, Person.darshi)
  //     ? this.allMonthlySummaries.map((x, index, array) =>
  //         index === 0 ? x.savingsDarshi : x.savingsDarshi + array[index - 1].savingsDarshi
  //       )
  //     : undefined;
  //   const commonData = this.getVisibility(AshiChart.allCumulativeMonthlySummaries, Person.common)
  //     ? this.allMonthlySummaries.map((x, index, array) =>
  //         index === 0 ? x.savingsCommon : x.savingsCommon + array[index - 1].savingsCommon
  //       )
  //     : undefined;
  //   return this.getDataSet(asangaData, darshiData, commonData);
  // }

  get allMonthlySavingsData() {
    return {
      labels: this.allMonthsChartLabels,
      datasets: this.getDataSetForChart(AshiChart.allMonthlySummaries),
    };
  }

  get allMonthlySavingsDataCumalative() {
    return {
      labels: this.allMonthsChartLabels,
      datasets: this.getDataSetForChart(AshiChart.allCumulativeMonthlySummaries),
    };
  }

  // get allYearlySavingsDataSet() {
  //   const asangaData = this.getVisibility(AshiChart.allYearlySummaries, Person.asanga)
  //     ? this.allYearlySummaries.map((x) => x.savingsAsanga)
  //     : undefined;
  //   const darshiData = this.getVisibility(AshiChart.allYearlySummaries, Person.darshi)
  //     ? this.allYearlySummaries.map((x) => x.savingsDarshi)
  //     : undefined;
  //   const commonData = this.getVisibility(AshiChart.allYearlySummaries, Person.common)
  //     ? this.allYearlySummaries.map((x) => x.savingsCommon)
  //     : undefined;
  //   return this.getDataSet(asangaData, darshiData, commonData);
  // }

  // get allYearlySavingsCumalativeDataSet() {
  //   const asangaData = this.getVisibility(AshiChart.allCumulativeYearlySummaries, Person.asanga)
  //     ? this.allYearlySummaries.map((x, index, array) =>
  //         index === 0 ? x.savingsAsanga : x.savingsAsanga + array[index - 1].savingsAsanga
  //       )
  //     : undefined;
  //   const darshiData = this.getVisibility(AshiChart.allCumulativeYearlySummaries, Person.darshi)
  //     ? this.allYearlySummaries.map((x, index, array) =>
  //         index === 0 ? x.savingsDarshi : x.savingsDarshi + array[index - 1].savingsDarshi
  //       )
  //     : undefined;
  //   const commonData = this.getVisibility(AshiChart.allCumulativeYearlySummaries, Person.common)
  //     ? this.allYearlySummaries.map((x, index, array) =>
  //         index === 0 ? x.savingsCommon : x.savingsCommon + array[index - 1].savingsCommon
  //       )
  //     : undefined;
  //   return this.getDataSet(asangaData, darshiData, commonData);
  // }

  get allYearlySavingsData() {
    return {
      labels: this.allYearsChartLabels,
      datasets: this.getDataSetForChart(AshiChart.allYearlySummaries),
    };
  }

  get allYearlySavingsDataCumalative() {
    return {
      labels: this.allYearsChartLabels,
      datasets: this.getDataSetForChart(AshiChart.allCumulativeYearlySummaries),
    };
  }

  getMonthlySavingsDataForYear = (year: number) => {
    const labels = this.getMonthLabels(this.allMonthlySummaries.filter((x) => x.year === year));
    return {
      labels: labels,
      datasets: this.getDataSetForChart(AshiChart.monthlySummariesForYear, year),
    };
  };

  getMonthlySavingsDataForYearCumalative = (year: number) => {
    const labels = this.getMonthLabels(this.allMonthlySummaries.filter((x) => x.year === year));
    return {
      labels: labels,
      datasets: this.getDataSetForChart(AshiChart.monthlyCumulativeSummariesForYear, year),
    };
  };

  get monthlySavingsDataForLast12Months() {
    const labels = this.getMonthLabels(this.monthlySummariesForLast12Months);
    return {
      labels: labels,
      datasets: this.getDataSetForChart(AshiChart.summariesForLast12Months),
    };
  }

  get monthlySavingsDataForLast12MonthsCumalative() {
    const labels = this.getMonthLabels(this.monthlySummariesForLast12Months);
    return {
      labels: labels,
      datasets: this.getDataSetForChart(AshiChart.cumulativeSummariesForLast12Months),
    };
  }

  get totalAsangaSavings() {
    if (this.allYearlySummaries.length === 0) {
      return 0;
    }
    return this.allYearlySummaries.map((x) => x.savingsAsanga).reduce((previous, current) => previous + current);
  }

  get totalDarshiSavings() {
    if (this.allYearlySummaries.length === 0) {
      return 0;
    }
    return this.allYearlySummaries.map((x) => x.savingsDarshi).reduce((previous, current) => previous + current);
  }

  get totalCommonSavings() {
    if (this.allYearlySummaries.length === 0) {
      return 0;
    }
    return this.allYearlySummaries.map((x) => x.savingsCommon).reduce((previous, current) => previous + current);
  }

  getName = (person: Person) => {
    if (person === Person.asanga) {
      return 'Asanga';
    } else if (person === Person.darshi) {
      return 'Darshi';
    } else if (person === Person.common) {
      return 'Common';
    }
  };

  get allYears() {
    return this.allYearlySummaries.map((x) => x.year);
  }
}
