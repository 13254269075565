import { observer } from 'mobx-react-lite';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Grid, Input, Table } from 'semantic-ui-react';
import { ModelBase } from '../../models/model-base';
import { StoreBase } from '../../stores/StoreBase';
import { FieldProps } from './FieldProps';
import GenericListTableBody from './GenericListTableBody';
import GenericListTableHeader from './GenericListTableHeader';

interface Props<T extends ModelBase> {
  items: T[];
  store: StoreBase<T>;
  otherTitle: string;
  columns: FieldProps[];
  className?: string;
  getFilteredList?: (items: T[]) => T[];
  getDeleteWarningMessage?: (item: T) => string;
  deleteClicked?: (item: T) => void;
  editSubmited?: (item: T) => void;
  refreshClicked?: () => void;
  noEdit?: boolean;
  noDelete?: boolean;
}

const GenericItemList = <T extends ModelBase>({
  items,
  store,
  otherTitle,
  columns,
  className,
  getFilteredList,
  getDeleteWarningMessage,
  deleteClicked,
  editSubmited,
  refreshClicked,
  noEdit,
  noDelete,
}: Props<T>) => {
  const [searchText, setSearchText] = useState('');
  const [itemsToShow, setItemsToShow] = useState([] as T[]);

  const handleSearchTextChanged = () => {
    if (searchText.length === 0 || !getFilteredList) {
      setItemsToShow(items);
    }
    if (getFilteredList) {
      setItemsToShow(getFilteredList(items) ?? []);
    }
  };

  useEffect(() => {
    setItemsToShow(items);
  }, [setItemsToShow, items]);

  const noButtonColumn = noEdit && noDelete;

  return (
    <>
      {getFilteredList && (
        <Grid stackable>
          <Grid.Column floated='right' width={8} className='search-text'>
            <Input
              icon='search'
              placeholder='Search...'
              value={searchText}
              onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                setSearchText(e.currentTarget.value);
                handleSearchTextChanged();
              }}
            />
          </Grid.Column>
        </Grid>
      )}
      <Table
        stackable
        sortable
        celled
        striped
        compact='very'
        className={className}
      >
        <GenericListTableHeader
          columns={columns}
          refreshClicked={refreshClicked}
          noButtonColumn={noButtonColumn}
        />
        <GenericListTableBody
          items={itemsToShow}
          store={store}
          otherTitle={otherTitle}
          columns={columns}
          getDeleteWarningMessage={getDeleteWarningMessage}
          deleteClicked={deleteClicked}
          editSubmited={editSubmited}
          noEdit={noEdit}
          noDelete={noDelete}
        />
      </Table>
    </>
  );
};

export default observer(GenericItemList);
