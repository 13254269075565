import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Tab } from 'semantic-ui-react';

import LoadingComponent from '../layout/LoadingComponent';
import { useStore } from '../stores/Store';
import ByYearSummary from './ByYearSummary';
import Last12MonthSumary from './Last12MonthSumary';
import YearlySummary from './YearlySummary';

const FullSummary = () => {
  const { incomeExpenseStore } = useStore();
  const { loading, loadMonthly, loadYearly } = incomeExpenseStore;

  useEffect(() => {
    loadMonthly();
    loadYearly();
  }, [loadMonthly, loadYearly]);

  if (loading) {
    return <LoadingComponent />;
  }

  const getMainTabPanes = () => {
    return [
      {
        menuItem: 'Last 12 Months',
        render: () => (
          <Tab.Pane>
            <Last12MonthSumary />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'By Year',
        render: () => (
          <Tab.Pane>
            <ByYearSummary />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Yearly',
        render: () => (
          <Tab.Pane>
            <YearlySummary />
          </Tab.Pane>
        ),
      },
    ];
  };

  return <Tab panes={getMainTabPanes()} />;
};

export default observer(FullSummary);
