import { Car } from '../models/car';
import { StoreBase } from './StoreBase';

export default class CarStore extends StoreBase<Car> {
  constructor() {
    super('Cars');
  }

  protected sortItems = (items: Car[]) => {
    return items.sort(({ active: a }, { active: b }) => {
      return a > b ? 1 : a < b ? -1 : 0;
    });
  };

  protected getDisplayText = (item: Car) => {
    return item.carName ?? 'Unknown Car';
  };
}
