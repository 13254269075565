import { SpecialIncome } from '../models/special-income';
import { PagedStoreBase } from './PagedStoreBase';

export default class SpecialIncomeStore extends PagedStoreBase<SpecialIncome> {
  constructor() {
    super('SpecialIncomes');
  }

  protected sortItems = (items: SpecialIncome[]) => {
    return items.sort(({ incomeDate: ad }, { incomeDate: bd }) => {
      const a = ad.getTime();
      const b = bd.getTime();
      return b > a ? 1 : b < a ? -1 : 0;
    });
  };

  setItem = (item: SpecialIncome) => {
    item.incomeDate = new Date(item.incomeDate);
    this.itemRegistry.set(item.id, item);
  };
}
