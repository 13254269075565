import { useStore } from '../../../stores/Store';
import GenericDataList from '../GenericDataList'; 
import { EducationExpenseType } from '../../../models/education-expense-type';

const EducationExpenseTypeList = () => {
  const { educationExpenseTypeStore } = useStore();

  return (
    <GenericDataList<EducationExpenseType>
      store={educationExpenseTypeStore}
      displayProp='expenseName'
      title='Education Expense Types'
      editTitle='Education Expense Type'
      colTitle='Expense Name'
      addButtonTitle='Add Education Expense Type'
    />
  );
};

export default EducationExpenseTypeList;
