import { observer } from 'mobx-react-lite';
import { Tab } from 'semantic-ui-react';

import { Person } from '../stores/IncomeExpenseStore';
import { useStore } from '../stores/Store';
import MonthlySavingsChart from './charts/MonthlySavingsChart';
import MonthlySummaries from './summaries-display/MonthlySummaries';

interface Props {
  year?: number;
  last12Months?: boolean;
}

const ChartAndDataByMonth = ({ year, last12Months }: Props) => {
  const { incomeExpenseStore } = useStore();
  const { getName, monthlySummariesForLast12Months, getMonthlySummariesForYear } = incomeExpenseStore;

  const getDataTabPane = (person: Person) => {
    const data = last12Months ? monthlySummariesForLast12Months : !!year ? getMonthlySummariesForYear(year) : [];
    const title = last12Months ? 'Monthly Savings for Last 12 Months' : !!year ? `Monthly Savings for ${year}` : '';
    return {
      menuItem: getName(person),
      render: () => (
        <Tab.Pane>
          <MonthlySummaries title={title} data={data} person={person} />
        </Tab.Pane>
      ),
    };
  };

  const dataPanes = () => [getDataTabPane(Person.common), getDataTabPane(Person.asanga), getDataTabPane(Person.darshi)];

  const getTabPanes = () => {
    return [
      {
        menuItem: 'Chart',
        render: () => (
          <Tab.Pane>
            <MonthlySavingsChart showTotal={false} last12Months={last12Months} year={year} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Data',
        render: () => (
          <Tab.Pane>
            <Tab panes={dataPanes()} />
          </Tab.Pane>
        ),
      },
    ];
  };

  return <Tab panes={getTabPanes()} />;
};

export default observer(ChartAndDataByMonth);
