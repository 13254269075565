import { AsangaIncome } from '../models/asanga-income';
import { StoreBase } from './StoreBase';

export default class AsangaIncomeStore extends StoreBase<AsangaIncome> {
  constructor() {
    super('AsangaIncome');
  }

  protected sortItems = (items: AsangaIncome[]) => {
    return items.sort(({ incomeDate: ad }, { incomeDate: bd }) => {
      const a = ad.getTime();
      const b = bd.getTime();
      return b > a ? 1 : b < a ? -1 : 0;
    });
  };
}
