import React from 'react';
import { Button, Card, Header, SemanticCOLORS } from 'semantic-ui-react';
import { store, useStore } from '../../stores/Store';

interface Props {
  title: string;
  message: string;
  titleColor?: string;
  titleBackColor?: string;
  color?: SemanticCOLORS;
  okHandler: () => Promise<boolean>;
}

const ConfirmDialog = ({
  title,
  message,
  titleColor,
  titleBackColor,
  color,
  okHandler,
}: Props) => {
  const { modalStore } = useStore();

  const titleColorToUse = titleColor ?? 'gray';
  const titleBackColorToUse = titleBackColor ?? 'lightgray';

  return (
    <Card color={color} style={{ margin: 'auto' }}>
      <Card.Content style={{ backgroundColor: titleBackColorToUse }}>
        <Header
          as='h2'
          content={title}
          textAlign='center'
          style={{ color: titleColorToUse }}
        />
      </Card.Content>
      <Card.Content description={message} />
      <Card.Content extra>
        <Button
          floated='right'
          positive
          content='Yes'
          onClick={async () => {
            if (await okHandler()) {
              modalStore.closeModal();
            }
          }}
        />
        <Button
          floated='right'
          type='button'
          content='Cancel'
          onClick={() => modalStore.closeModal()}
        />
      </Card.Content>
    </Card>
  );
};

export const getConformationFunc = (
  title: string,
  message: string,
  okHandler: () => Promise<boolean>,
  titleColor?: string,
  titleBackColor?: string,
  color?: SemanticCOLORS
) => {
  store.modalStore.openModal(
    <ConfirmDialog
      title={title}
      message={message}
      okHandler={okHandler}
      color={color}
      titleColor={titleColor}
      titleBackColor={titleBackColor}
    />
  );
};

export default ConfirmDialog;
