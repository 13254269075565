import { useStore } from '../../../stores/Store';
import GenericDataList from '../GenericDataList'; 
import { SpecialExpensePaymentMethod } from '../../../models/special-expense-payment-method';

const SpecialExpensePaymentMethodList = () => {
  const { specialExpensePaymentMethodStore } = useStore();

  return (
    <GenericDataList<SpecialExpensePaymentMethod>
      store={specialExpensePaymentMethodStore}
      displayProp='method'
      title='Special Expense Payment Methods'
      editTitle='Special Expense Payment Method'
      colTitle='Method'
      addButtonTitle='Add Special Expense Payment Method'
    />
  );
};

export default SpecialExpensePaymentMethodList;
