import { EducationIncome } from '../models/education-income';
import { PagedStoreBase } from './PagedStoreBase';

export default class EducationIncomeStore extends PagedStoreBase<EducationIncome> {
  constructor() {
    super('EducationIncomes');
  }

  protected sortItems = (items: EducationIncome[]) => {
    return items.sort(({ incomeDate: ad }, { incomeDate: bd }) => {
      const a = ad.getTime();
      const b = bd.getTime();
      return b > a ? 1 : b < a ? -1 : 0;
    });
  };

  setItem = (item: EducationIncome) => {
    item.incomeDate = new Date(item.incomeDate);
    this.itemRegistry.set(item.id, item);
  };
}
