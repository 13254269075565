import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  Header,
  Label,
  Menu,
  Segment,
  Tab,
} from 'semantic-ui-react';
import FileUploadWidget from '../../common/file-upload/FileUploadWidget';
import ExtraExpenses from '../../controls/card-transactions/ExtraExpenses';
import NewExpenses from '../../controls/card-transactions/NewExpenses';
import NewIncomes from '../../controls/card-transactions/NewIncomes';
import LoadingComponent from '../../layout/LoadingComponent';
import { useStore } from '../../stores/Store';

const MissingExpensesPage = () => {
  const { cardTransactionStore } = useStore();
  const {
    loadItemsIfNeeded,
    uploadCardFiles,
    loading,
    loadExtraExpenses,
    extraExpenses,
    expenseItems,
  } = cardTransactionStore;
  const [uploadVisible, setUploadVisible] = useState(false);
  const [newFileCount, setNewFileCount] = useState(0);

  useEffect(() => {
    loadItemsIfNeeded();
  }, [loadItemsIfNeeded, newFileCount]);

  useEffect(() => {
    loadExtraExpenses();
  }, [loadExtraExpenses, newFileCount]);

  const uploadFileHandler = async (files: File[]): Promise<boolean> => {
    const ok = await uploadCardFiles(files);
    if (ok) {
      setTimeout(() => {
        setUploadVisible(false);
        setNewFileCount(newFileCount + 1);
      }, 100);
    }
    return ok;
  };

  const fileUpload = (
    <div style={{ marginTop: '20px' }}>
      <FileUploadWidget uploadFiles={uploadFileHandler} />
    </div>
  );

  const panes = [
    {
      menuItem: (
        <Menu.Item key='newExpenses'>
          New<Label>{expenseItems.length}</Label>
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <NewExpenses />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item key='extraExpenses'>
          Extra<Label>{extraExpenses.length}</Label>
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <ExtraExpenses extraExpenses={extraExpenses} />
        </Tab.Pane>
      ),
    },
  ];

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <Accordion
        as={Menu}
        vertical
        fluid
        style={{ width: '100%', marginTop: '20px', marginBottom: '20px' }}
      >
        <Menu.Item>
          <Accordion.Title
            active={uploadVisible}
            content='Upload Card File'
            index={0}
            onClick={() => setUploadVisible(!uploadVisible)}
          />
          <Accordion.Content active={uploadVisible} content={fileUpload} />
        </Menu.Item>
      </Accordion>
      <Tab panes={panes} />
      <Segment.Group>
        <Segment>
          <Header as='h2'>Incomes From Cards</Header>
        </Segment>
        <Segment>
          <NewIncomes />
        </Segment>
      </Segment.Group>
    </>
  );
};

export default observer(MissingExpensesPage);
