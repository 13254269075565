import { useStore } from '../../../stores/Store';
import GenericDataList from '../GenericDataList'; 
import { ExpenseType } from '../../../models/expense-type';

const ExpenseTypeList = () => {
  const { expenseTypeStore } = useStore();

  return (
    <GenericDataList<ExpenseType>
      store={expenseTypeStore}
      displayProp='typeName'
      title='Expense Types'
      editTitle='Expense Type'
      colTitle='Type Name'
      addButtonTitle='Add Expense Type'
    />
  );
};

export default ExpenseTypeList;
