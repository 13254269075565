import { observer } from 'mobx-react-lite';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, Menu, Sidebar } from 'semantic-ui-react';
import { useStore } from '../stores/Store';
import CommonMenu from './CommonMenu';

const SideBar = (props: any) => {
  const {
    commonStore: { sidebarVisible, hideSidebar },
    userStore: { logout },
  } = useStore();

  return (
    <Sidebar.Pushable
      style={{
        minWidth: '100%',
        minHeight: 'calc(100vh - 3em)',
        marginTop: '3em',
      }}
    >
      <Sidebar
        as={Menu}
        animation='overlay'
        // icon='labeled'
        // inverted
        onHide={() => hideSidebar()}
        vertical
        visible={sidebarVisible}
        width='wide'
        onShow={() => window.scrollTo(0, 0)}
      >
        <Menu.Item
          name='Home'
          as={NavLink}
          to='/home'
          icon='home'
          style={{ marginTop: '20px' }}
          onClick={hideSidebar}
        />
        <CommonMenu classes={''} hideSidebar={hideSidebar} />
        <Menu.Item
          as='a'
          onClick={() => {
            hideSidebar();
            logout();
          }}
        >
          <Icon name='power' />
          Logout
        </Menu.Item>
      </Sidebar>

      <Sidebar.Pusher
        dimmed={sidebarVisible}
        children={props.children}
        style={{
          minWidth: '100%',
          minHeight: 'calc(100vh - 0.8em)',
          marginTop: '0.8em',
        }}
      />
    </Sidebar.Pushable>
  );
};

export default observer(SideBar);
