import { EducationIncomeType } from '../models/education-income-type';
import { StoreBase } from './StoreBase';

export default class EducationIncomeTypeStore extends StoreBase<EducationIncomeType> {
  constructor() {
    super('EducationIncomeTypes');
  }

  protected sortItems = (items: EducationIncomeType[]) => {
    return items.sort(({ incomeName: a }, { incomeName: b }) => {
      return a > b ? 1 : a < b ? -1 : 0;
    });
  };

  protected getDisplayText = (item: EducationIncomeType) => {
    return item.incomeName;
  };
}
