import React, { useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';
import './App.css';
import NavBar from './NavBar';
import SideBar from './SideBar';
import { Route, Switch } from 'react-router-dom';
import { useStore } from '../stores/Store';
import LoadingComponent from './LoadingComponent';
import { observer } from 'mobx-react-lite';
import ModalContainer from '../common/modals/ModalContainer';
import HomePage from '../pages/home/HomePage';
import PrivateRoute from './PrivateRoute';
import AccountsPage from '../pages/accounts/AccountsPage';
import NotFound from '../pages/errors/NotFound';
import ExpenseList from '../pages/expenses/ExpenseList';
import ExpenseTypeList from '../pages/data-lists/expense-types/ExpenseTypeList';
import ExpenseCategoryList from '../pages/data-lists/expense-categories/ExpenseCategoryList';
import StoreList from '../pages/data-lists/stores/StoreList';
import PaymentMethodList from '../pages/data-lists/payment-methods/PaymentMethodList';
import IncomeTypeList from '../pages/data-lists/income-types/IncomeTypeList';
import EducationExpenseTypeList from '../pages/data-lists/education-expense-types/EducationExpenseTypeList';
import EducationIncomeTypeList from '../pages/data-lists/education-income-types/EducationIncomeTypeList';
import IncomeList from '../pages/incomes/IncomeList';
import SpecialExpenseTypeList from '../pages/data-lists/special-expense-types/SpecialExpenseTypeList';
import SpecialIncomeTypeList from '../pages/data-lists/special-income-types/SpecialIncomeTypeList';
import SpecialExpensePaymentMethodList from '../pages/data-lists/special-expense-payment-methods/SpecialExpensePaymentMethodList';
import SpecialExpenseList from '../pages/special-expenses/SpecialExpenseList';
import SpecialIncomeList from '../pages/special-incomes/SpecialIncomeList';
import EducationIncomeList from '../pages/education-incomes/EducationIncomeList';
import EducationExpenseList from '../pages/education-expenses/EducationExpenseList';
import AsangaIncome from '../pages/asanga-income/AsangaIncomePage';
import AutoPaymentsPage from '../pages/auto-payments/AutoPaymentsPage';
import MissingExpensesPage from '../pages/missing-expenses/MissingExpensesPage';
import CostcoCardPayments from '../pages/costco-card-payments/CostcoCardPayments';
import GasList from '../pages/gas/GasList';
import DuplicateExpenses from '../pages/duplicate-expenses/DuplicateExpenses';

function App() {
  // const location = useLocation();
  const { commonStore, userStore } = useStore();

  useEffect(() => {
    if (commonStore.token) {
      userStore.getUser().finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded();
    }
  }, [commonStore, userStore]);

  if (!commonStore.appLoaded) {
    return <LoadingComponent content='Loading...' />;
  }

  return (
    <>
      <ToastContainer position='bottom-right' hideProgressBar />
      <ModalContainer />
      <Route exact path='/' component={HomePage} />
      <Route
        path={'/(.+)'}
        render={() => (
          <>
            <NavBar />
            <SideBar>
              <Container style={{ padding: '10px', scroll: 'auto' }}>
                <Switch>
                  <PrivateRoute exact path='/home' component={AccountsPage} />
                  <PrivateRoute exact path='/gas' component={GasList} />
                  <PrivateRoute
                    exact
                    path='/expenses'
                    component={ExpenseList}
                  />
                  <PrivateRoute exact path='/incomes' component={IncomeList} />
                  <PrivateRoute
                    exact
                    path='/expensetypes'
                    component={ExpenseTypeList}
                  />
                  <PrivateRoute
                    exact
                    path='/expensecategories'
                    component={ExpenseCategoryList}
                  />
                  <PrivateRoute exact path='/stores' component={StoreList} />
                  <PrivateRoute
                    exact
                    path='/paymentmethods'
                    component={PaymentMethodList}
                  />
                  <PrivateRoute
                    exact
                    path='/incometypes'
                    component={IncomeTypeList}
                  />
                  <PrivateRoute
                    exact
                    path='/educationexpensetypes'
                    component={EducationExpenseTypeList}
                  />
                  <PrivateRoute
                    exact
                    path='/educationincometypes'
                    component={EducationIncomeTypeList}
                  />
                  <PrivateRoute
                    exact
                    path='/specialexpensetypes'
                    component={SpecialExpenseTypeList}
                  />
                  <PrivateRoute
                    exact
                    path='/specialincometypes'
                    component={SpecialIncomeTypeList}
                  />
                  <PrivateRoute
                    exact
                    path='/specialpaymentmethods'
                    component={SpecialExpensePaymentMethodList}
                  />
                  <PrivateRoute
                    exact
                    path='/specialincomes'
                    component={SpecialIncomeList}
                  />
                  <PrivateRoute
                    exact
                    path='/specialexpenses'
                    component={SpecialExpenseList}
                  />
                  <PrivateRoute
                    exact
                    path='/educationincomes'
                    component={EducationIncomeList}
                  />
                  <PrivateRoute
                    exact
                    path='/educationexpenses'
                    component={EducationExpenseList}
                  />
                  <PrivateRoute
                    exact
                    path='/asangaincome'
                    component={AsangaIncome}
                  />
                  <PrivateRoute
                    exact
                    path='/duplicateexpenses'
                    component={DuplicateExpenses}
                  />
                  <PrivateRoute
                    exact
                    path='/autopayments'
                    component={AutoPaymentsPage}
                  />
                  <PrivateRoute
                    exact
                    path='/missingexpenses'
                    component={MissingExpensesPage}
                  />
                  <PrivateRoute
                    exact
                    path='/costcocardamounts'
                    component={CostcoCardPayments}
                  />

                  <PrivateRoute exact path='/test' component={IncomeList} />
                  <PrivateRoute component={NotFound} />
                </Switch>
              </Container>
            </SideBar>
          </>
        )}
      />
    </>
  );
}

export default observer(App);
