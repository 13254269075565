import { observer } from 'mobx-react-lite';
import { SyntheticEvent, useEffect } from 'react';
import { Button, Grid, Input, Table } from 'semantic-ui-react';
import LoadingComponent from '../../layout/LoadingComponent';
import { ModelBase } from '../../models/model-base';
import { PagedStoreBase } from '../../stores/PagedStoreBase';
import { useStore } from '../../stores/Store';
import { FieldProps } from './FieldProps';
import GenericEditForm from './GenericEditForm';
import GenericListTableBody from './GenericListTableBody';
import GenericListTableHeader from './GenericListTableHeader';

interface Props<T extends ModelBase> {
  store: PagedStoreBase<T>;
  title: string;
  otherTitle: string;
  columns: FieldProps[];
  className?: string;
  getDeleteWarningMessage: (item: T) => string;
  getNewItem: () => T;
}

const GenericList = <T extends ModelBase>({
  store,
  otherTitle,
  columns,
  className,
  getDeleteWarningMessage,
  getNewItem,
}: Props<T>) => {
  const { modalStore } = useStore();

  const {
    loadItemsIfNeeded,
    loadItems,
    items,
    pagination,
    loading,
    pageToShow,
    setPageToShow,
    searchText,
    setSearchText,
  } = store;

  useEffect(() => {
    loadItemsIfNeeded();
  }, [loadItemsIfNeeded]);

  const handleInputChange = (e: any, { value }: any) => {
    setPageToShow(value);
  };

  const editForm = (item?: T) => {
    const itemToUse = item ?? getNewItem();
    return (
      <GenericEditForm
        store={store}
        item={itemToUse}
        title={itemToUse.id > 0 ? `Edit ${otherTitle}` : `Add ${otherTitle}`}
        fields={columns}
      />
    );
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <Grid stackable>
        <Grid.Column floated='left' textAlign='left' width={8}>
          <Button
            color='blue'
            content={'Add ' + otherTitle}
            onClick={() => {
              modalStore.openModal(editForm());
            }}
          ></Button>
        </Grid.Column>
        <Grid.Column floated='right' width={8} className='search-text'>
          <Input
            icon='search'
            placeholder='Search...'
            value={searchText}
            onChange={(e: SyntheticEvent<HTMLInputElement>) => {
              setSearchText(e.currentTarget.value);
            }}
          />
        </Grid.Column>
      </Grid>
      <Table
        stackable
        sortable
        celled
        striped
        compact='very'
        className={className}
      >
        <GenericListTableHeader
          columns={columns}
          refreshClicked={() => loadItems()}
        />
        <GenericListTableBody
          items={items}
          store={store}
          otherTitle={otherTitle}
          columns={columns}
          getDeleteWarningMessage={getDeleteWarningMessage}
        />
        {pagination && pagination?.totalPages > 1 ? (
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='8'>
                <Grid>
                  <Grid.Column floated='left' width={10}>
                    <div>
                      Showing Page {pageToShow} of {pagination?.totalPages}
                    </div>
                  </Grid.Column>
                  <Grid.Column floated='right' width={6} textAlign='right'>
                    <div>
                      Goto Page{' '}
                      <input
                        type='number'
                        min={1}
                        max={pagination?.totalPages}
                        value={pageToShow}
                        onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                          handleInputChange(e, {
                            value: e.currentTarget.value,
                          });
                        }}
                        style={{ maxWidth: '50px' }}
                      />
                    </div>
                  </Grid.Column>
                </Grid>
                <Input
                  min={1}
                  max={pagination?.totalPages}
                  onChange={handleInputChange}
                  type='range'
                  value={pageToShow}
                  style={{ width: '100%' }}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        ) : null}
      </Table>
    </>
  );
};

export default observer(GenericList);
