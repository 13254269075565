import { SpecialExpensePaymentMethod } from '../models/special-expense-payment-method';
import { StoreBase } from './StoreBase';

export default class SpecialExpensePaymentMethodStore extends StoreBase<SpecialExpensePaymentMethod> {
  constructor() {
    super('SpecialExpensePaymentMethods');
  }

  protected sortItems = (items: SpecialExpensePaymentMethod[]) => {
    return items.sort(({ method: a }, { method: b }) => {
      return a > b ? 1 : a < b ? -1 : 0;
    });
  };

  protected getDisplayText = (item: SpecialExpensePaymentMethod) => {
    return item.method;
  };
}
