import React from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';

interface Props {
  hideSidebar: () => void;
  classes: string;
}

const CommonMenu = ({ hideSidebar, classes }: Props) => {
  return (
    <>
      <Menu.Item
        name='Gas'
        as={NavLink}
        to='/gas'
        icon='car'
        className={classes}
        onClick={hideSidebar}
      />
      <Menu.Item
        name='Expenses'
        as={NavLink}
        to='/expenses'
        icon='credit card outline'
        className={classes}
        onClick={hideSidebar}
      />
      <Menu.Item
        name='Incomes'
        as={NavLink}
        to='/incomes'
        icon='money'
        className={classes}
        onClick={hideSidebar}
      />
      <Menu.Item className={classes}>
        <Dropdown pointing='top left' text='Actions'>
          <Dropdown.Menu>
            <Dropdown.Item
              as={NavLink}
              to='/autopayments'
              text='Auto Payments'
              icon='setting'
              onClick={hideSidebar}
            />
            <Dropdown.Divider />
            <Dropdown.Item
              as={NavLink}
              to='/missingexpenses'
              text='Missing Expenses'
              icon='autoprefixer'
              onClick={hideSidebar}
            />
            <Dropdown.Divider />
            <Dropdown.Item
              as={NavLink}
              to='/costcocardamounts'
              text='Costco Card Amounts'
              icon='payment'
              onClick={hideSidebar}
            />
            <Dropdown.Divider />
            <Dropdown.Item
              as={NavLink}
              to='/asangaincome'
              text="Enter Asanga's Income"
              icon='money'
              onClick={hideSidebar}
            />
            <Dropdown.Divider />
            <Dropdown.Item
              as={NavLink}
              to='/duplicateexpenses'
              text='Find Possible Duplicates'
              icon='copy'
              onClick={hideSidebar}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
      <Menu.Item className={classes}>
        <Dropdown pointing='top left' text='Other Data'>
          <Dropdown.Menu>
            <Dropdown.Item
              as={NavLink}
              to='/stores'
              text='Stores'
              icon='list alternate outline'
              onClick={hideSidebar}
            />
            <Dropdown.Divider />
            <Dropdown.Item
              as={NavLink}
              to='/expensetypes'
              text='Expense Types'
              icon='list alternate outline'
              onClick={hideSidebar}
            />
            <Dropdown.Item
              as={NavLink}
              to='/incometypes'
              text='Income Types'
              icon='list alternate outline'
              onClick={hideSidebar}
            />
            <Dropdown.Item
              as={NavLink}
              to='/paymentmethods'
              text='Payment Methods'
              icon='list alternate outline'
              onClick={hideSidebar}
            />
            <Dropdown.Divider />
            <Dropdown.Item
              as={NavLink}
              to='/specialexpensetypes'
              text='Special Expense Types'
              icon='list alternate outline'
              onClick={hideSidebar}
            />
            <Dropdown.Item
              as={NavLink}
              to='/specialincometypes'
              text='Special Income Types'
              icon='list alternate outline'
              onClick={hideSidebar}
            />
            <Dropdown.Item
              as={NavLink}
              to='/specialpaymentmethods'
              text='Special Payment Methods'
              icon='list alternate outline'
              onClick={hideSidebar}
            />
            <Dropdown.Divider />
            <Dropdown.Item
              as={NavLink}
              to='/specialexpenses'
              text='Special Expenses'
              icon='list alternate outline'
              onClick={hideSidebar}
            />
            <Dropdown.Item
              as={NavLink}
              to='/specialincomes'
              text='Special Incomes'
              icon='list alternate outline'
              onClick={hideSidebar}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
      <Menu.Item className={classes}>
        <Dropdown pointing='top left' text='Education'>
          <Dropdown.Menu>
            <Dropdown.Item
              as={NavLink}
              to='/educationexpenses'
              text='Education Expenses'
              icon='graduation'
              onClick={hideSidebar}
            />
            <Dropdown.Item
              as={NavLink}
              to='/educationincomes'
              text='Education Incomes'
              icon='graduation'
              onClick={hideSidebar}
            />
            <Dropdown.Divider />
            <Dropdown.Item
              as={NavLink}
              to='/educationexpensetypes'
              text='Education Expense Types'
              icon='list alternate outline'
              onClick={hideSidebar}
            />
            <Dropdown.Item
              as={NavLink}
              to='/educationincometypes'
              text='Education Income Types'
              icon='list alternate outline'
              onClick={hideSidebar}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </>
  );
};

export default CommonMenu;
